import styled from 'styled-components';

import { ReactComponent as DiscountIcon } from '../../../../../../assets/discount.svg';
import { colors } from '../../../../../../styles';
import { ReactComponent as TransactionLinkIcon } from '../assets/link.svg';

export const Title = styled.h2`
    margin: 30px 0 4px;
    font-size: 16px;
    font-weight: bold;
`;

export const Subtitle = styled.h2`
    color: #718596;
    font-size: 14px;
    margin: ${({ margin }) => margin || '0'};
`;

export const FormWrapper = styled.div`
    display: grid;
    gap: 16px;
`;

export const CommissionBlock = styled.div`
    background: #d3fde9;
    padding: 10px;
    color: #024525;
    border-radius: 4px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 15px;
`;

export const Paragraph = styled.p`
    margin: 0;
    padding: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
`;

export const ModifiedDiscountIcon = styled(DiscountIcon)`
    margin-right: 10px;
`;

export const TransactionDetails = styled.div`
    height: 277px;
    margin: 30px 0 16px;
    background: #ffffff;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: grid;
    grid-template-rows: 187px 90px;
`;

export const NetworkProviderDetails = styled.div`
    display: grid;
    grid-template-columns: 10px auto;
    column-gap: 26px;
    padding-left: 31px;
    padding-top: 32px;
    padding-bottom: 24px;
`;

export const Artwork = styled.div`
    min-height: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ModifiedTransactionLinkIcon = styled(TransactionLinkIcon)`
    margin-bottom: 3px;
`;

export const Details = styled.div`
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 24px;
`;

export const TransactionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlowTitle = styled(Paragraph)`
    color: ${colors.subGrey};
    letter-spacing: 0.01em;
    font-size: 10px;
    text-transform: uppercase;
`;

export const OutflowAccount = styled(Paragraph)`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #1a2630;
`;

export const Balance = styled(Paragraph)`
    font-size: 12px;
    line-height: 18px;
    color: #071827;
`;

export const PhoneNumber = styled(Paragraph)`
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #1a2630;
`;

export const Amount = styled(Paragraph)`
    font-size: 11px;
    line-height: 16px;
    color: #071827;
`;

export const NewtworkProviderAndAmount = styled.div`
    display: grid;
    grid-template-columns: 32px 1fr;
    margin-top: 3px;
    column-gap: 8px;
`;

export const NetworkProviderLogo = styled.img`
    height: 32px;
    width: 32px;
`;

export const SelectedNetworkProvider = styled.div`
    display: flex;
    align-items: center;
    margin-top: 11px;
`;

export const SelectedNetworkProviderLogo = styled.img`
    height: 16px;
    width: 16px;
    margin-right: 8px;
`;

export const AmountTitle = styled(Paragraph)`
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #718596;
`;

export const TotalTransactionAmount = styled(Paragraph)`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #071827;
`;

export const TotalAmountBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 24px;

    border-top: 1px dashed #b6c1de;
    margin: 0 8px;
`;

export const TransactionPinFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const WalletBalance = styled.div`
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.01em;
    margin-left: 16px;
    margin-top: -12px;
`;

export const WalletBalanceText = styled(Paragraph)`
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
`;

export const WalletBalanceAmount = styled(WalletBalanceText)`
    font-weight: bold;
    margin-left: 2px;
`;

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;
