import React, { Fragment, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Badge } from '../../../containers/BadgeContainer';
import { getWalletBalanceAsync } from '../../../redux/ducks/account/wallet/actions';
import { colors } from '../../../styles';
import { breakUpFormatPrice } from '../../../utils/currency/formatPriceWithComma';
import { LottieControl } from '../../lottie';

import animationData from './assets/balance_loader.json';
import { ReactComponent as PayBillsIcon } from './assets/pay-bills.svg';
import { ReactComponent as AirtimeIcon } from './assets/phone.svg';
import { ReactComponent as TopupIcon } from './assets/topup.svg';
import { ReactComponent as TransferIcon } from './assets/transfer.svg';

const FlexContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const WalletBalanceWrapper = styled.span`
    display: flex;
`;

const WalletBalance = styled.h3`
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
    padding: 3px 0px;
    align-self: ${({ align }) => align || 'flex-start'};
`;

const WalletBalanceAmount = styled.h3`
    margin-left: 3px;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
`;

const Dot = styled.span`
    height: 6px;
    width: 6px;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    margin-right: 1px;
    margin-left: 1px;
`;

const WalletBalanceTitle = styled.h6`
    font-size: 12px;
    margin-bottom: 4px;
    color: #ffffff;
    font-weight: 400;
    line-height: 18px;
`;

const IconWrapper = styled.div`
    display: flex;
    margin: 32px 0;
    justify-content: space-around;
    align-items: center;
`;

const IconText = styled.h6`
    font-size: 12px;
    font-weight: 400;
    color: 071827;
    margin-top: 10px;
`;

const StatusContainer = styled.div`
    margin: auto;
`;

const WalletStatusBadge = styled.div`
    color: ${({ color }) => color || null};
    background-color: ${({ bg }) => bg || null};
    font-weight: 400;
    font-size: 10px;
    width: 295px;
    height: 20px;
    border-radius: 0px 0px 8px 8px;
    text-align: center;
    alignitems: center;
    padding: 3px 0px 1px 0px;
`;

const TopRightCorner = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const ClickableItem = styled.div`
    cursor: pointer;
`;

const WalletBadge2 = ({ classNames, addMoney, sendMoney, buyAirtime, payBills }) => {
    const dispatch = useDispatch();
    const fetchingWalletBalance = useSelector((state) => state.account.wallet.fetchingWalletBalance);
    const balance = useSelector((state) => state.account.wallet.balance);
    const hideBalance = useSelector((state) => state.account.wallet.hideBalance);
    const [amount, setAmount] = useState([]);
    const [walletStatus, setWalletStatus] = useState('');

    const CurrentStatus = () => {
        dispatch(getWalletBalanceAsync()).then((res) => {
            res && setWalletStatus(res);
        });
    };

    useEffect(() => {
        setAmount(breakUpFormatPrice(balance || 0));
        CurrentStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balance]);

    return (
        <Fragment>
            <Badge
                boxShadow
                height="96px"
                background={colors.background.walletBadge}
                backgroundImage={'linear-gradient(94.68deg, #227eff 27.32%, #7e42f5 72.68%)'}
                justifyContent="center"
            >
                <FlexContainer>
                    <WalletBalanceTitle>Wallet Balance</WalletBalanceTitle>
                    {hideBalance ? (
                        <WalletBalanceAmount>
                            <Dot />
                            <Dot />
                            .
                            <Dot />
                            <Dot />
                        </WalletBalanceAmount>
                    ) : (
                        <WalletBalanceWrapper>
                            <WalletBalance>{amount[0]}</WalletBalance>
                            <WalletBalanceAmount>{amount[1]}</WalletBalanceAmount>
                            <WalletBalance align={'flex-end'}>{amount[2]}</WalletBalance>
                        </WalletBalanceWrapper>
                    )}
                </FlexContainer>
                {fetchingWalletBalance && (
                    <TopRightCorner>
                        <LottieControl
                            height={40}
                            width={40}
                            play={fetchingWalletBalance}
                            animationData={animationData}
                        />
                    </TopRightCorner>
                )}
            </Badge>
            {walletStatus.status === 'ACTIVE' && (
                <StatusContainer>
                    <WalletStatusBadge color={colors.darkGreen1} bg={colors.greenBg}>
                        Wallet Active
                    </WalletStatusBadge>
                </StatusContainer>
            )}
            {walletStatus.status === 'INACTIVE' && (
                <StatusContainer>
                    <WalletStatusBadge color={colors.warning} bg={colors.lightYellowBg}>
                        Wallet InActive
                    </WalletStatusBadge>
                </StatusContainer>
            )}
            {walletStatus.status === 'BLOCKED' && (
                <StatusContainer>
                    <WalletStatusBadge color={colors.newRed} bg={colors.newRedBg}>
                        Wallet Blocked
                    </WalletStatusBadge>
                </StatusContainer>
            )}
            {walletStatus.status === 'SUSPENDED' && (
                <StatusContainer>
                    <WalletStatusBadge color={colors.deepBlue} bg={colors.skyBlue}>
                        Wallet Suspended
                    </WalletStatusBadge>
                </StatusContainer>
            )}
            <IconWrapper>
                <ClickableItem>
                    <FlexContainer onClick={addMoney}>
                        <TopupIcon />
                        <IconText>Top Up</IconText>
                    </FlexContainer>
                </ClickableItem>
                <ClickableItem>
                    <FlexContainer onClick={sendMoney}>
                        <TransferIcon />
                        <IconText>Transfer</IconText>
                    </FlexContainer>
                </ClickableItem>
                <ClickableItem>
                    <FlexContainer onClick={buyAirtime}>
                        <AirtimeIcon />
                        <IconText>Airtime</IconText>
                    </FlexContainer>
                </ClickableItem>
                <ClickableItem>
                    <FlexContainer onClick={payBills}>
                        <PayBillsIcon />
                        <IconText>Pay Bills</IconText>
                    </FlexContainer>
                </ClickableItem>
            </IconWrapper>
        </Fragment>
    );
};

export default WalletBadge2;
