import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import parse from 'html-react-parser';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Loader, NewTextAreaWithLabel, PageLogo, RippleButton, TopHeader } from '../../../../components';
import { ImageCropper } from '../../../../components/popup/image-cropper';
import { Title, SubTitle } from '../../../../containers/MessageContainer';
import {
    ScreenContainer,
    FlexCenteredBlock,
    SectionWrapper,
    FlexContainer,
} from '../../../../containers/ScreenContainer';
import {
    addCommentToComplaint,
    getComplaintComments,
    uploadTicketFile,
} from '../../../../redux/ducks/applications/complaints/actions';
import { colors } from '../../../../styles';
import { fileToFormData } from '../../../../utils/files/fileToFormData';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';

import emptyIcon from './assets/empty.svg';
import { ReactComponent as SendIcon } from './assets/send.svg';
import { ReactComponent as ShareImageIcon } from './assets/share-image.svg';
import { ValidationSchema } from './ValidationSchema';

const ButtonCard = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 375px;
    z-index: 100;
    display: flex;
    padding: 0px 16px 24px;
    background-color: ${colors.white};
    align-items: flex-end;

    @media (max-width: 576px) {
        width: 100%;
    }
`;

const TextShareCont = styled.div`
    position: relative;
    width: 100%;
`;

const ShareImageCont = styled.label`
    position: absolute;
    bottom: 18px;
    right: 19px;

    & > input {
        height: 0;
        width: 0;
        overflow: hidden;
        background: transparent;
    }
`;

const AnchorLink = styled.a``;

const CommentContainer = styled.div`
    padding-bottom: 50px;
`;

const ComplaintsViewComment = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.applications.complaints.isLoading);

    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const [ticketId, setTicketId] = useState();
    const [reference, setReference] = useState('');
    const [showCropper, setShowCropper] = useState(false);
    const [picture, setPicture] = useState('');
    const [fileData, setFileData] = useState();
    const pageNumber = 1;
    const pageSize = 100;

    useEffect(() => {
        setReference(location?.state?.reference);
        setTicketId(location?.state?.ticketId);
    }, [location]);

    const getComments = () => {
        dispatch(getComplaintComments(reference, pageNumber, pageSize)).then((data) => {
            const { ticketFiles, ticketComments } = data || {};
            if (ticketFiles?.length > 0 || ticketComments?.length > 0) {
                const modifiedComments = ticketComments?.map((x) => {
                    x.type = 'comment';
                    x.createdTime = x.commentedTime;
                    return x;
                });
                setComments([...modifiedComments, ...ticketFiles]);
            } else {
                return setComments([]);
            }
        });
    };

    useEffect(() => {
        if (reference) {
            getComments();
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, location, reference]);

    const pictureAction = (image) => {
        setPicture(image);
        setShowCropper(true);
    };

    const handleChangeFile = (event) => {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            const image = event.target.result;
            if (!showCropper) {
                pictureAction(image);
            }
        });

        if (event.target.files[0]) {
            const file = event.target.files[0];
            setFileData(file);

            if (file.size > 1024 * 1024 * 2) {
                toast.error('File size cannot be more than 2MB');
                return;
            } else {
                pictureAction(file);
                reader.readAsDataURL(file);
            }
        } else return;
    };

    const onImageError = () => {
        toast.error('An error occurred while processing your image, please try again');
        setShowCropper(false);
    };

    const submit = async (objectUrl) => {
        try {
            const data = {
                uri: objectUrl,
                type: 'image/*',
                name: 'Complaint image',
                fileName: fileData.name,
            };
            const formData = await fileToFormData(data.uri, data);
            if (formData) {
                dispatch(uploadTicketFile(ticketId, formData)).then((res) => {
                    if (res) {
                        toast.success('Comment added successfully');
                        setShowCropper(false);
                        getComments();
                    } else {
                        onImageError();
                    }
                });
            } else {
                onImageError();
            }
        } catch (error) {
            onImageError();
        }
    };

    const onCropperSave = async (url) => {
        if (url) {
            submit(url);
        } else {
            toast.error('Image is still processing, wait for a sec...');
        }
    };

    if (loading || isLoading) return <Loader />;

    return (
        <DesktopBackgroundLayout>
            <TopHeader size={'14px'} color={colors.boldDark} title={'Comment'} noBorderBottom />
            <ScreenContainer paddingBottom="120px" minHeight={'100vh'} color={colors.lightestGray}>
                {comments?.length > 0 ? (
                    <CommentContainer>
                        {comments
                            ?.filter((comment) => comment?.isPublic === true)
                            ?.sort((a, b) => new Date(a?.createdTime) - new Date(b?.createdTime))
                            ?.map((comment, index) => {
                                const commenterName = comment?.commenter?.firstName || comment?.creator?.firstName;

                                return (
                                    <SectionWrapper
                                        bgcolor={colors.white}
                                        radius={'4px'}
                                        width={'100%'}
                                        minHeight={'95px'}
                                        padding={'16px'}
                                        margin={'0 0 8px'}
                                        key={index}
                                    >
                                        <FlexContainer>
                                            <SubTitle
                                                font={'10px'}
                                                weight={'700'}
                                                lineHeight={'16px'}
                                                color={colors.sabiBlue}
                                                bottom={'8px'}
                                            >
                                                {commenterName === 'App'
                                                    ? 'You'.toUpperCase()
                                                    : commenterName?.toUpperCase() || 'SUPPORT'}
                                                :
                                            </SubTitle>
                                            {comment?.type !== 'comment' && (
                                                <AnchorLink
                                                    href={comment?.imageDownloadUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    download
                                                >
                                                    <SubTitle
                                                        textTransform={'uppercase'}
                                                        font={'10px'}
                                                        weight={'700'}
                                                        lineHeight={'16px'}
                                                        color={colors.textGreen}
                                                    >
                                                        Download File
                                                    </SubTitle>
                                                </AnchorLink>
                                            )}
                                        </FlexContainer>
                                        <SubTitle
                                            font={'14px'}
                                            weight={'400'}
                                            lineHeight={'21px'}
                                            color={colors.boldDark}
                                            wrap={'break-word'}
                                            bottom={'8px'}
                                        >
                                            {comment?.type === 'comment' ? parse(comment?.content) : comment?.name}
                                        </SubTitle>
                                        <SubTitle
                                            font={'12px'}
                                            weight={'400'}
                                            lineHeight={'18px'}
                                            color={colors.themeTextColor11}
                                        >
                                            Created on {moment(comment?.createdTime).format('lll')}
                                        </SubTitle>
                                    </SectionWrapper>
                                );
                            })}
                    </CommentContainer>
                ) : (
                    <FlexCenteredBlock>
                        <PageLogo
                            Icon={emptyIcon}
                            width={'121.93px'}
                            height={'112px'}
                            iconHeight={'112px'}
                            iconWidth={'121.93px'}
                            margin={'72px 0 40px'}
                            borderRadius={'initial'}
                        />
                        <Title weight={'700'} color={colors.boldDark} size={'14px'} lineHeight={'21px'} bottom={'0px'}>
                            No comment has been added
                        </Title>
                        <SubTitle font={'12px'} weight={'400'} lineHeight={'18px'} color={colors.lightDark}>
                            Tap below to add comment
                        </SubTitle>
                    </FlexCenteredBlock>
                )}

                <Formik
                    initialValues={{
                        comment: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={(values) => {
                        const formDataToSend = new FormData();
                        formDataToSend.append('commentDetails', values.comment);
                        if (formDataToSend) {
                            dispatch(addCommentToComplaint(ticketId, formDataToSend)).then((res) => {
                                if (res) {
                                    toast.success('Comment added successfully');
                                    getComments();
                                }
                            });
                        }
                    }}
                >
                    {({ values, handleSubmit }) => (
                        <ButtonCard>
                            <TextShareCont>
                                <NewTextAreaWithLabel
                                    label={'Enter comment'}
                                    name={'comment'}
                                    placeholder={'Enter comment'}
                                    height={values.comment.length > 0 ? '150px' : '56px'}
                                    padding={'8px 16px'}
                                    minHeight={'56px'}
                                    width={'100%'}
                                    labelBlockBottom={'0px'}
                                    labelContWidth={'100%'}
                                    errorBottom={'-12px'}
                                />
                                {!values.comment?.length > 0 && (
                                    <ShareImageCont>
                                        <input type="file" accept="image/*" onChange={handleChangeFile} />
                                        <ShareImageIcon />
                                    </ShareImageCont>
                                )}
                            </TextShareCont>
                            <RippleButton
                                width={'56px'}
                                height={'56px'}
                                type={'submit'}
                                disabled={!values.comment}
                                left={'16px'}
                                onClick={handleSubmit}
                            >
                                <SendIcon />
                            </RippleButton>
                        </ButtonCard>
                    )}
                </Formik>

                {showCropper && (
                    <ImageCropper
                        avatar={picture}
                        onCancel={() => setShowCropper(false)}
                        onSave={onCropperSave}
                        cancelBgColor={colors.white}
                        cancelBorder={`1px solid ${colors.themeTextColor11}`}
                        buttonWidth={'45%'}
                        cancelColor={colors.themeTextColor11}
                        saveText={'Send'}
                    />
                )}
            </ScreenContainer>
        </DesktopBackgroundLayout>
    );
};

export default ComplaintsViewComment;
