import React, { Fragment, useEffect, useState, useCallback } from 'react';

import algoliarecommend from '@algolia/recommend';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import aa from 'search-insights';
import useRouter from 'use-react-router';

import {
    TopHeader,
    MerchbuyAddtoCartPopup,
    InfoPopupDialog,
    DeliveryLocationPopup,
    SharePopup,
    BuynowPopup,
    Loader,
    ProductSharePopup,
} from '../../../../components';
import { FeatureUndergoingFixes } from '../../../../components/popup/feature-undergoing-fixes';
import { currentHost } from '../../../../config/API';
import { merchbuyActions } from '../../../../redux/ducks/applications/merchbuy/actions';
import {
    PRODUCT_DETAILS,
    INITIATE_ADD_TO_CART,
    INITIATE_INSTANT_PURCHASE,
} from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import { toTitleCasePerWord } from '../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { ReactComponent as BuyIcon } from '../assets/buy.svg';
import { ReactComponent as RecentlyViewedIcon } from '../assets/recently_viewed.svg';
import { ReactComponent as RecommendedProductsIcon } from '../assets/recommended_detail.svg';
import { ReactComponent as SearchSvg } from '../assets/search.svg';
import { BottomButton, SuggestedProduct, UnAvailableProduct } from '../components';
import { OOSEducationBackground } from '../saved-items/educate';
import { Main, Space } from '../styles';
import { getPriceFromBulkPrices } from '../utils/getPriceFromBulkPrices';
import { productCategoryName } from '../utils/getProductCatNameById';

import { BuyNowEducation } from './buyNowEducation';
import ProductAvailability from './product-availability';
import ProductDescription from './product-description';
import { CartCount, TopIconWrapper } from './styles';

const searchIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_V2;

const ProductDetails = () => {
    const history = useHistory();
    const { location } = useRouter();
    const productData = location?.state?.items ? location?.state?.items : location?.state || {};
    const dispatch = useDispatch();

    const { pathname } = location;
    const { state } = location;
    const pattern = `(.*)?${'/actions/merchbuy/product/:id'}`;
    const secondPattern = `(.*)?${'/actions/merchbuy/product/:id/:warehouseId'}`;
    const match = matchPath(pathname, { path: Object.keys(productData).length > 0 ? pattern : secondPattern }) || {};

    const cartItems = useSelector((state) => state.applications.merchbuy.shopCart);
    const userId = useSelector((state) => state.user.userId);
    const productCategeries = useSelector((state) => state.applications.merchbuy.merchbuyProductCategeries);
    const searchTerm = useSelector((state) => state.applications.merchbuy.productsSearch);
    const features = useSelector((state) => state.user.features);
    const minimumOrderValue = useSelector((state) => state.applications.merchbuy.minimumOrderValue);

    const [openFeatureFixes, setOpenFeatureFixes] = useState(false);
    const [openProductDetailsPopup, setOpenProductDetailsPopup] = useState(false);
    const [showEducate, setShowEducate] = useState(false);
    const [openProductSharePopup, setOpenProductSharePopup] = useState(false);
    const [openCartPopup, setOpenCartPopup] = useState(false);
    const [openBuynowPopup, setOpenBuynowPopup] = useState(false);
    const [openContactMerchant, setOpenContactMerchant] = useState(false);
    const [deliveryLocation, setDeliveryLocation] = useState(JSON.parse(localStorage.getItem('deliveryLocation')));
    const [recentlyViewedProducts, setRecentlyViewedProducts] = useState(
        JSON.parse(localStorage.getItem('recently-viewed-products')) || [],
    );
    const filteredRecentProducts = recentlyViewedProducts.filter((x) => (x?.productId || x?.id) && x?.warehouseId);
    const [recommendedProducts, setGetRecommendedProducts] = useState([]);
    const [openShareLinks, setOpenShareLinks] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [doAnimate, setDoAnimate] = useState(false);
    const [isAnimate, setIsAnimate] = useState(false);
    const [buyNowAnimate, setBuyNowAnimate] = useState(false);
    const [showError, setShowError] = useState(false);
    const [selectedMoq, setSelectedMoq] = useState(productData?.bulkPrices && productData?.bulkPrices[0]?.moq);

    const productIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_V2;
    const algoliaClient = algoliarecommend(
        process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
    );

    const slicedRecentProducts = filteredRecentProducts?.slice(0, 4);
    const slicedRecommendedProducts = recommendedProducts?.slice(0, 4);

    const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(deliveryLocation === null);
    const [loaderText] = useState('Loading');
    const [loading, setLoading] = useState(false);
    const [isProductAvailable, setIsProductAvailable] = useState(true);
    const [isLiked, setIsLiked] = useState(false);
    const [educatePage, setEducatePage] = useState(1);
    const [serverProductInformation, setServerProductInformation] = useState({});
    const oosEducation = localStorage.getItem('OOSEducation') && JSON.parse(localStorage.getItem('OOSEducation'));
    const [userEducate, setUserEducate] = useState(oosEducation?.productDetails || showEducate ? false : true);

    const url = `${currentHost}/actions/merchbuy/product/${match?.params?.id}/${productData?.warehouseId}`;

    const { images, productDescription, productName } = productData || {};
    const imageURL = images?.baseImageUrl;
    const getLocation = (data) => {
        if (data.state) {
            setDeliveryLocation(data);
            setOpenDeliverLocationPopup(!openDeliverLocationPopup);
        }
    };

    useEffect(() => {
        document.title = `Sabi - ${toTitleCasePerWord(productName)}`;
    }, [productName]);

    const getRecentlyViewedProducts = useCallback(
        (product) => {
            const productsInLocalStorage = [...recentlyViewedProducts];

            const productIndexInLocalStorage = productsInLocalStorage.findIndex(
                (product) => (product?.id || product?.productId) === match?.params?.id,
            );

            if (productIndexInLocalStorage === -1) {
                productsInLocalStorage.unshift(product);

                localStorage.setItem('recently-viewed-products', JSON.stringify(productsInLocalStorage));

                setRecentlyViewedProducts(productsInLocalStorage);
            }
        },
        [match.params.id, recentlyViewedProducts],
    );

    const getRecommendedProducts = useCallback(() => {
        algoliaClient
            .getRelatedProducts([
                {
                    indexName: productIndex,
                    objectID: productData?.objectID,
                },
            ])
            .then(({ results }) => {
                if (results[0]?.hits?.length > 0) {
                    setGetRecommendedProducts(results[0]?.hits);
                } else {
                    // let's load recommendation from our DB as fallback
                    throw new Error('No recommendations yet for this product');
                }
            })
            .catch((err) => {
                setGetRecommendedProducts([]);
            });
    }, [algoliaClient, productData, productIndex]);

    useEffect(() => {
        const { __queryID } = location?.state || {};
        const eventName = 'Product Viewed';
        const eventType = 'viewedObjectIDs';
        const eventProperties = {
            objectIDs: [productData?.objectID],
            userToken: userId,
            index: productIndex,
            eventName,
        };

        if (__queryID) {
            eventProperties.queryID = __queryID;
        }

        aa(eventType, {
            ...eventProperties,
        });
    }, [location.state, productData.objectID, productIndex, userId]);

    useEffect(() => {
        getRecommendedProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (productData?.id || productData?.productId) {
            mixPanel.track(PRODUCT_DETAILS, {
                'Product id': productData?.productId || productData?.id,
                'Product name': productData?.productName || productData?.name,
            });
        }
    }, [productData]);

    useEffect(() => {
        return () => dispatch(merchbuyActions.unsubscribe());
    }, [dispatch]);

    const fireConversionAnalyticsEvent = () => {
        aa('convertedObjectIDsAfterSearch', {
            userToken: userId,
            eventName: 'Product Added to Cart after Search',
            index: searchIndex,
            queryID: productData?.__queryID,
            objectIDs: [productData?.objectID || productData?.id],
            Position: [productData?.__position],
        });
    };

    const handleAddToCart = () => {
        mixPanel.track(INITIATE_ADD_TO_CART, {
            'name of the product': serverProductInformation?.productName,
            'product id': serverProductInformation?.productId,
        });
        fireConversionAnalyticsEvent();

        setTimeout(() => {
            setOpenCartPopup(!openCartPopup);
            setDoAnimate(false);
        }, 300);
    };

    const checkIfProductIsAvailableForPurchase = useCallback(
        (stateName, isOnMerchBuy) => {
            return !isOnMerchBuy || stateName !== (deliveryLocation?.state || 'Lagos');
        },
        [deliveryLocation],
    );

    const handleBuyNow = async (newProduct) => {
        mixPanel.track(INITIATE_INSTANT_PURCHASE, {
            'Product name': serverProductInformation.productName,
            'Product ID': serverProductInformation?.id,
            Time: new Date().toLocaleString(),
        });

        const price = getPriceFromBulkPrices(newProduct);
        const totalBuyNowPrice = newProduct?.quantity * price;

        if (minimumOrderValue?.value > totalBuyNowPrice) {
            setShowError(true);
        } else {
            setOpenBuynowPopup(!openBuynowPopup);

            const params = {
                productVariantIds: [serverProductInformation?.id],
                warehouseId: productData?.warehouseId,
            };

            setLoading(true);
            const [product] = await dispatch(merchbuyActions.cartItemsValidation(params));

            if (product) {
                const { quantity, bulkPrices, isOnMerchBuy, stateName } = product;
                if (
                    checkIfProductIsAvailableForPurchase(stateName, isOnMerchBuy) ||
                    selectedMoq < bulkPrices[0]?.quantity ||
                    selectedMoq > quantity
                ) {
                    setIsProductAvailable(false);
                    setLoading(false);
                    toast.error('Product not available for purchase');
                } else {
                    history.push({
                        pathname: '/actions/merchbuy/checkout',
                        state: {
                            items: [newProduct],
                            fromBuyNow: true,
                        },
                    });
                }
            } else {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        productData?.bulkPrices?.sort((a, b) => (a.moq || a.quantity) - (b.moq || b.quantity));
        const showEducation =
            localStorage.getItem('instantPurchaseTutorial') &&
            JSON.parse(localStorage.getItem('instantPurchaseTutorial'));
        !showEducation && setShowEducate(true);
    }, [productData.bulkPrices]);

    useEffect(() => {
        const getSavedItems = async () => {
            const isItemAdded = await dispatch(merchbuyActions.getSavedItems(1, 1000));
            const isSaved = isItemAdded?.findIndex((x) => x?.id === match?.params?.id);
            if (isSaved === -1) return;
            setIsLiked(true);
        };
        getSavedItems();
    }, [dispatch, match.params.id]);

    const handleClose = () => {
        setDoAnimate(true);
        setTimeout(() => {
            setOpenCartPopup(!openCartPopup);
        }, 300);
    };

    const handleCloseEducate = () => {
        setTimeout(() => {
            setShowEducate(false);
        }, 500);
    };

    const handleEducateDone = () => {
        handleCloseEducate();
        localStorage.setItem('instantPurchaseTutorial', JSON.stringify(true));
    };

    const handleBuynowClick = () => {
        const pathKey = 'Shared Product URL';
        if (userId) {
            if (!features?.buyNow?.status) return setOpenFeatureFixes(true);
            setTimeout(() => {
                setOpenBuynowPopup(true);
                setBuyNowAnimate(false);
            }, 300);
        } else {
            localStorage.setItem(pathKey, location.pathname);
            history.push('/launch');
        }
    };

    useEffect(() => {
        dispatch(merchbuyActions.getMinimumOrderValue());
    }, [dispatch]);

    useEffect(() => {
        const getProductDetails = async () => {
            setLoading(true);
            const params = {
                productVariantIds: [match?.params?.id || productData?.productId || productData?.id],
                warehouseId: productData?.warehouseId || match?.params?.warehouseId,
            };
            const [product] = await dispatch(merchbuyActions.getProductDetailsById(params));
            if (product) {
                const { stateName, isOnMerchBuy } = product;
                if (
                    checkIfProductIsAvailableForPurchase(stateName, isOnMerchBuy) ||
                    product?.quantity < product?.bulkPrices[0]?.quantity
                ) {
                    setIsProductAvailable(false);
                }
                getRecentlyViewedProducts({ ...product, warehouseId: productData?.warehouseId });
                setServerProductInformation(product);
            }
            setLoading(false);
        };

        getProductDetails();
    }, [
        checkIfProductIsAvailableForPurchase,
        dispatch,
        getRecentlyViewedProducts,
        match.params.id,
        match.params.warehouseId,
        productData,
    ]);

    if (loading || !Object.keys(serverProductInformation).length) return <Loader text={loaderText} width={'140px'} />;

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader
                    backLink={state?.fromBuyNow ? `/actions/merchbuy` : null}
                    backAction={() => {
                        if (openProductDetailsPopup) {
                            setOpenProductDetailsPopup(false);
                        }
                    }}
                    title={`Product${openProductDetailsPopup ? ' Description' : ' Details'}`}
                >
                    <TopIconWrapper>
                        <div onClick={() => history.push('/actions/merchbuy/search')}>
                            <SearchSvg />
                        </div>
                        <div onClick={() => history.push('/actions/merchbuy/cart')}>
                            <BuyIcon />
                            <CartCount>{cartItems ? cartItems?.length : 0}</CartCount>
                        </div>
                    </TopIconWrapper>
                </TopHeader>
                {openProductDetailsPopup ? (
                    <ProductDescription
                        data={serverProductInformation}
                        productCategoryName={productCategoryName}
                        productCategeries={productCategeries}
                    />
                ) : (
                    <Fragment>
                        {showEducate && (
                            <BuyNowEducation
                                handleEducateDone={handleEducateDone}
                                educatePage={educatePage}
                                setEducatePage={setEducatePage}
                            />
                        )}
                        {userEducate && !showEducate && isProductAvailable && <OOSEducationBackground />}
                        <Main paddingBottom={'80px'}>
                            {isProductAvailable ? (
                                <ProductAvailability
                                    isLiked={isLiked}
                                    setIsLiked={setIsLiked}
                                    showEducate={showEducate}
                                    productData={productData}
                                    productDetails={serverProductInformation}
                                    openProductSharePopup={openProductSharePopup}
                                    setOpenProductSharePopup={setOpenProductSharePopup}
                                    setDoAnimate={setDoAnimate}
                                    isAnimate={isAnimate}
                                    setIsAnimate={setIsAnimate}
                                    location={location}
                                    userEducate={userEducate}
                                    setUserEducate={setUserEducate}
                                    oosEducation={oosEducation}
                                    isProductAvailable={isProductAvailable}
                                />
                            ) : (
                                <UnAvailableProduct />
                            )}

                            <Space />
                            {recommendedProducts.length > 0 && (
                                <SuggestedProduct
                                    products={slicedRecommendedProducts}
                                    searchTerm={searchTerm}
                                    title={'Recommended Products'}
                                    Icon={RecommendedProductsIcon}
                                />
                            )}
                            {filteredRecentProducts.length > 0 && (
                                <SuggestedProduct
                                    products={slicedRecentProducts}
                                    searchTerm={searchTerm}
                                    title={'Recently Viewed'}
                                    Icon={RecentlyViewedIcon}
                                />
                            )}
                        </Main>
                        {isProductAvailable && (
                            <BottomButton handleAddToCart={handleAddToCart} handleBuynowClick={handleBuynowClick} />
                        )}
                    </Fragment>
                )}
                <ProductSharePopup
                    open={openProductSharePopup}
                    cancel={() => setOpenProductSharePopup(!openProductSharePopup)}
                    url={url}
                    imageURL={imageURL}
                    productName={productName}
                    productDescription={productDescription}
                    animate={doAnimate}
                />
                <MerchbuyAddtoCartPopup
                    open={openCartPopup}
                    data={match?.params?.warehouseId ? serverProductInformation : productData}
                    productDetails={serverProductInformation}
                    cancel={handleClose}
                    confirm={merchbuyActions.addProductToCart}
                    animate={doAnimate}
                />
                <BuynowPopup
                    open={openBuynowPopup}
                    data={match?.params?.warehouseId ? serverProductInformation : productData}
                    productDetails={serverProductInformation}
                    cancel={() => {
                        setBuyNowAnimate(true);
                        setTimeout(() => {
                            setOpenBuynowPopup(!openBuynowPopup);
                        }, 300);
                    }}
                    confirm={handleBuyNow}
                    selectedMoq={selectedMoq}
                    setSelectedMoq={setSelectedMoq}
                    showError={showError}
                    minimumOrderValue={minimumOrderValue}
                    totalPrice={totalPrice}
                    setTotalPrice={setTotalPrice}
                    animate={buyNowAnimate}
                />
                <InfoPopupDialog
                    open={openContactMerchant}
                    cancel={() => setOpenContactMerchant(!openContactMerchant)}
                    title={'Contact Merchant'}
                    message={'Coming Soon.'}
                    padding={'38px 8px'}
                    messagePadding={'5px 0 40px 0'}
                />
                {userId ? (
                    <>
                        {openDeliverLocationPopup && (
                            <>
                                <DeliveryLocationPopup
                                    open={openDeliverLocationPopup}
                                    getLocation={getLocation}
                                    cancel={() => {
                                        setOpenProductDetailsPopup(!openProductDetailsPopup);
                                    }}
                                />
                                <MerchbuyAddtoCartPopup
                                    open={openCartPopup}
                                    data={productData}
                                    cancel={handleClose}
                                    confirm={merchbuyActions.addProductToCart}
                                    animate={doAnimate}
                                />
                                <InfoPopupDialog
                                    open={openContactMerchant}
                                    cancel={() => setOpenContactMerchant(!openContactMerchant)}
                                    title={'Contact Merchant'}
                                    message={'Coming Soon.'}
                                    padding={'38px 8px'}
                                    messagePadding={'5px 0 40px 0'}
                                />
                            </>
                        )}
                        <DeliveryLocationPopup
                            open={openDeliverLocationPopup}
                            getLocation={getLocation}
                            cancel={() => {
                                setOpenProductDetailsPopup(!openProductDetailsPopup);
                            }}
                        />
                        <MerchbuyAddtoCartPopup
                            open={openCartPopup}
                            data={productData}
                            productDetails={serverProductInformation}
                            cancel={handleClose}
                            confirm={merchbuyActions.addProductToCart}
                            animate={doAnimate}
                        />
                        <InfoPopupDialog
                            open={openContactMerchant}
                            cancel={() => setOpenContactMerchant(!openContactMerchant)}
                            title={'Contact Merchant'}
                            message={'Coming Soon.'}
                            padding={'38px 8px'}
                            messagePadding={'5px 0 40px 0'}
                        />
                    </>
                ) : null}
                {openShareLinks && (
                    <SharePopup
                        url={url}
                        marketingMessage={''}
                        shareMessage={'Share link to product'}
                        open={openShareLinks}
                        setOpen={setOpenShareLinks}
                    />
                )}
                {openFeatureFixes && <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default ProductDetails;
