/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment, useState, useEffect } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import aa from 'search-insights';
import styled from 'styled-components';
import useRouter from 'use-react-router';

import { SuccessfulOrder } from '../..';
import { TopHeader, RippleButton, Loader, usePaystackPayment, TextareaWithLabel } from '../../../../../components';
import { Button } from '../../../../../components/button';
import { CheckoutFailedLoader, CheckoutLoader } from '../../../../../components/loader';
import { FeatureUndergoingFixes } from '../../../../../components/popup/feature-undergoing-fixes';
import { ScreenContainer } from '../../../../../containers/ScreenContainer';
import { orderStatus } from '../../../../../enums';
import { redirectedFromMerchbuy } from '../../../../../redux/ducks/account/wallet/actions';
import { merchbuyActions } from '../../../../../redux/ducks/applications/merchbuy/actions';
import { colors } from '../../../../../styles';
import { formatPrice } from '../../../../../utils/currency/formatPriceWithComma';
import { COMPLETE_INSTANT_PURCHASE, REORDER_COMPLETE } from '../../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../../utils/mix-panel/mixPanel';
import { toAbbr, toTitleCase, upperCaseToTitleCase } from '../../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import { ShippingCompany, EditDeliveryAddressDetails } from '../../components';
import { DeliveryAddressList } from '../../components/delivery-address/delivery-address-list';
import {
    Space,
    Container,
    PageSubSectionsTop,
    PageSubSectionsTopLeftTitle,
    FooterWrapper,
    TotalWrapper,
    DetailsSection,
    PaymentDetailsWrap,
    PaymentWrapper,
} from '../../styles';
import { getCartItemsSubTotal } from '../../utils/getCartItemsSubTotal';
import { CheckoutWithBankTranfer } from '../bank-transfer';
import { getProductInfoForMixPanel } from '../helper';
import Payment from '../payment';
import { PaymentInfo } from '../payment-info';
import { CheckoutWithWalletPin } from '../pin';

import Products from './products';

const ModifiedContainer = styled(Container)`
    width: 100%;
`;

const Text = styled.div`
    margin: ${({ margin }) => margin || '0'};
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    font-size: ${({ font }) => font || '12px'};
    color: ${({ color }) => color || null};
    line-height: 15px;
    align-self: ${({ align }) => align || 'center'};
`;

const ChangeDeliveryText = styled.h4`
    font-size: 13px;
    color: ${colors.deepBlue};
    font-weight: 600;
`;

const DeliveryDetailsWrapper = styled.div`
    display: grid;
    gap: 2px;
    padding-left: 23px;
    background: ${colors.white};
    padding: 1em;
`;

const InLineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CardUserName = styled.div`
    position: absolute;
    color: ${colors.themeTextColor11};
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    font-weight: 400;
    margin: ${({ margin }) => margin || '5% 0 0 11%'};
`;

const UserAcronymn = styled.div`
    background-color: ${colors.newPurple};
    border-radius: 50%;
    color: ${colors.purple};
    width: 32px;
    font-size: 10px;
    height: 32px;
    padding: 12px 5px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin: 0 0 10.5px 0px;
    font-weight: 500;
`;

const CardShopName = styled.div`
    position: absolute;
    color: ${colors.themeTextColor10};
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin-right: 10px;
    left: 15%;
    float: left;
    margin-top: -6%;
`;

const ButtonOuterWrapper = styled.div`
    background: ${colors.white};
    height: 96px;
    display: flex;
    align-items: center;
`;
const DeliveryWrapper = styled.div`
    background: ${colors.white};
    height: 96px;
`;

const ProductWrapper = styled.div`
    padding: 1px 1em 1em 1em;
    margin-bottom: 100px;
`;

const Checkout = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { location } = useRouter();
    const fromBuyNow = location?.state?.fromBuyNow;
    const itemsToCheckout = location?.state?.items;

    const isAgent = useSelector((state) => state?.user?.role === 'ROLE_AGENT');
    const userDeliveryDetails = useSelector((state) => state.applications.myShop.deliveryDetails);

    const walletStatus = useSelector((state) => state.account.wallet.status);
    const processingOrder = useSelector((state) => state.applications.merchbuy.processingOrder);
    const lastSavedDelivery = useSelector((state) => state.applications.merchbuy.lastSavedDeliveryAddress);
    const walletBalance = useSelector((state) => state.account.wallet.balance || 0);

    const cartItems = useSelector((state) => state.applications.merchbuy.shopCart);
    const redirected = useSelector((state) => state.account.wallet.redirectedFromMerchbuy);
    const user = useSelector((state) => state.user);
    const userId = useSelector((state) => state.user.userId);
    const [deliveryDetails, setDeliveryDetails] = useState(null);
    const [shippingDetails, setShippingDetails] = useState();
    const [paymentMethod, setPaymentMethod] = useState();
    const [orderId, setOrderId] = useState();
    const [statusEnum, setStatusEnum] = useState(0);

    const [showContinueButton, setShowContinueButton] = useState(false);
    const [showShippingPopup, setShowShippingPopup] = useState(false);
    const [showAddressPopup, setShowAddressPopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const [shouldPlaceOrder, setShouldPlaceOrder] = useState(false);

    const [step, setStep] = useState(orderStatus.delivery);
    const [subTotal] = useState(getCartItemsSubTotal(itemsToCheckout));

    const totalCartQuantity = () =>
        itemsToCheckout?.reduce((accum, current) => Number(accum) + Number(current.quantity), 0);
    const [totalQuantity] = useState(totalCartQuantity());

    const [totalPrice, setTotalPrice] = useState(subTotal);
    const [openPayment, setOpenPayment] = useState(false);

    const [deliveryStateLga] = useState(JSON.parse(localStorage.getItem('deliveryLocation')));
    const [paystackKey] = useState(process.env.REACT_APP_PAYSTACK_KEY);
    const [productCategory] = useState(
        itemsToCheckout?.flatMap((x) => x.product?.productDetails && [x.product.productDetails.productCategory]) || [],
    );
    const [shopNameList] = useState(itemsToCheckout?.flatMap((x) => x.shopData && [x.shopData.shopName]) || []);
    const [shopIDs] = useState(itemsToCheckout?.flatMap((x) => x.product && [x.product.shopId]) || []);
    const [productIDList] = useState(itemsToCheckout?.flatMap((x) => x.product && [x.product.id]) || []);
    const [productNameList] = useState(itemsToCheckout?.flatMap((x) => x.product && [x.product.name]) || []);
    const [showBankTransfer, setShowBankTransfer] = useState(false);
    const [bankName, setBankName] = useState('');
    const [accountNumber, setAccountNumber] = useState(0);
    const [loaderText, setLoaderText] = useState('Loading');
    const [orderNumber, setOrderNumber] = useState(0);
    const [transferReference, setTransferReference] = useState('');
    const [loading] = useState(false);
    const shopNumber = deliveryDetails?.shopNumber;
    const city = deliveryDetails?.city;
    const address = deliveryDetails?.shopAddress || deliveryDetails?.streetAddress || deliveryDetails?.address;
    const lga = deliveryDetails?.lga || deliveryDetails?.localGovt;
    const phoneNumber = deliveryDetails?.msisdn || deliveryDetails?.phoneNumber;

    const state = deliveryDetails?.state;
    const receiverName =
        deliveryDetails?.receiverName?.trim() ||
        deliveryDetails?.fullName?.trim() ||
        `${deliveryDetails?.firstName?.trim()} ${deliveryDetails?.lastName?.trim()}` ||
        '';
    const fullAddress = `${shopNumber} ${address} ${city} ${lga} ${state}`;

    const [firstName, lastName] = receiverName.split(' ');
    const productIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_V2;

    const [newShopName, setNewShopName] = useState('');
    const [newShopDetail, setNewShopDetail] = useState('');
    const initializePayment = usePaystackPayment();
    const [openFeatureFixes, setOpenFeatureFixes] = useState(false);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [showDeliveryFee, setShowDeliveryFee] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showFailedLoader, setShowFailedLoader] = useState(false);
    const [deliveryNote, setDeliveryNote] = useState('');

    const deliveryLocation =
        localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));
    const { state: shoppingLocation } = deliveryLocation || {};

    const reload = async () => {
        setShowFailedLoader(false);
        setShowDeliveryFee(false);
        setTotalPrice(subTotal);
        const res = await dispatch(merchbuyActions.verifyDeliveryLocationLga(state, lga));
        if (res.status) {
            if (res.data) setDeliveryFee(0);
            setShowDeliveryFee(true);
            setTotalPrice(subTotal + deliveryFee);
        } else setDeliveryFee(2000);
        setTotalPrice(subTotal + deliveryFee);
    };

    const isDeliveryDetailValid = () => {
        return deliveryDetails && state && lga && city && state === shoppingLocation;
    };

    const getAnyNewShopName = async (data) => {
        setNewShopName(data);
    };

    const shopDetail = async () => {
        const res = await dispatch(merchbuyActions.getSabiUserDetailByPhoneNumber(userDeliveryDetails?.phoneNumber));
        res && setNewShopDetail(res?.shops[0]?.details?.shopName);
    };

    const getShopName = (deliveryDetails) => {
        return `${deliveryDetails?.shopName || newShopDetail || newShopName}`;
    };

    const getName = (deliveryDetails) => {
        return `${
            deliveryDetails?.receiverName?.toUpperCase() ||
            deliveryDetails?.fullName?.toUpperCase() ||
            `${deliveryDetails?.firstName?.toUpperCase() || ''} ${deliveryDetails?.lastName?.toUpperCase() || ''}` ||
            ''
        }`;
    };

    const getShopAddress = (deliveryDetails) => {
        return `${`${deliveryDetails?.shopNumber ? deliveryDetails?.shopNumber + ', ' : ''}  
        ${deliveryDetails?.shopAddress || deliveryDetails?.streetAddress || deliveryDetails?.address || ''}`}`;
    };

    useEffect(() => {
        const isDeliveryFree = async () => {
            setShowDeliveryFee(false);
            setTotalPrice(subTotal);
            setShowFailedLoader(false);
            const res = await dispatch(merchbuyActions.verifyDeliveryLocationLga(state, lga));
            if (res.status) {
                if (res.data) setDeliveryFee(0);
                else setDeliveryFee(2000);
            }
            setShowLoader(false);
            setShowDeliveryFee(true);
            setTotalPrice(subTotal + deliveryFee);
            if (!res.status) {
                setShowFailedLoader(true);
                setTotalPrice(subTotal);
            }
        };

        let timer;

        if (deliveryDetails) {
            setShowLoader(true);
            setShowDeliveryFee(false);
            setTotalPrice(subTotal);

            timer = setTimeout(() => {
                isDeliveryFree();
            }, 2000);
        }
        // reload();
        return () => clearTimeout(timer);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, deliveryDetails, state, lga, subTotal, deliveryFee]);

    useEffect(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }));

    useEffect(() => {
        dispatch(merchbuyActions.getDeliveryAddressEditLimit());
        dispatch(merchbuyActions.getShopCart());
    }, [dispatch]);

    useEffect(() => {
        setShowContinueButton(isDeliveryDetailValid() && paymentMethod);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryDetails, paymentMethod, shoppingLocation]);

    const priceInfo = { subTotal, deliveryFee, totalPrice };

    useEffect(() => {
        if (!isAgent) {
            setDeliveryDetails({ ...userDeliveryDetails, contactType: 'SELF' });
        }
        if (!isAgent) {
            shopDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAgent]);

    useEffect(() => {
        if (!isAgent && deliveryDetails && !isDeliveryDetailValid()) {
            setShowEditPopup(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAgent, deliveryDetails, shoppingLocation]);

    useEffect(() => {
        if (redirected) {
            setDeliveryDetails(lastSavedDelivery);
            // setShippingDetails(null) //TODO when shipping gets added
            setStep(orderStatus.delivery);
            dispatch(redirectedFromMerchbuy(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, redirected]);

    const backAction = () => {
        if (fromBuyNow) {
            history.push({
                pathname: `/actions/merchbuy/product/${itemsToCheckout[0]?.id || itemsToCheckout[0]?.productId}`,
                state: { fromBuyNow: true, items: itemsToCheckout[0] },
            });
        } else if (step === orderStatus.delivery) {
            history.push('/actions/merchbuy/cart');
        }
    };

    const placeOrder = async () => {
        const products = [];
        const items = [...itemsToCheckout];
        for (let i = 0; i < items.length; i++) {
            const data = {
                itemId: items[i]?.productId || items[i]?.id,
                quantity: items[i]?.quantity,
                groupBuySplitId: items[i].groupBuySplitId,
                groupBuyId: items[i].groupBuyId,
                groupBuyBulkPrice: items[i].groupBuyProductBulkPrice,
                warehouseId: items[i].warehouseId,
            };
            products.push(data);
        }

        const payload = {
            products,
            checkoutUserType: 1,
            customerComment: deliveryNote,
            location: deliveryStateLga.state,
            orderDelivery: {
                address: fullAddress,
                deliveryCharge: deliveryFee || 0,
                deliveryCompany: shippingDetails?.partner || '',
                deliveryOption: shippingDetails?.option || '',
                firstName,
                lastName: lastName || firstName,
                lga,
                phoneNumber,
                serviceCharge: 0,
                state,
                subTotal,
                totalPrice,
            },
        };

        const res = await dispatch(merchbuyActions.placeOrder(payload, paymentMethod.enum));
        if (!fromBuyNow && res?.status) {
            if (cartItems?.length > itemsToCheckout?.length) {
                const diff = cartItems?.filter((x) => !itemsToCheckout?.some((i) => i.productId === x.productId)) || [];
                localStorage.setItem('cartItems', JSON.stringify(diff));
            } else {
                localStorage.removeItem('cartItems');
            }
        }
        return res;
    };

    const placeOrderHook = async () => {
        const data = getProductInfoForMixPanel(itemsToCheckout);
        const mixpanelPayload = {
            'Total cart value': subTotal,
            'Total item count': data.totalItemCount,
            'Total unique item': itemsToCheckout?.length,
            'Total shops': data.sortedItems.length,
            'Item ids': data.productIds,
            'Item names': data.productNames,
            'Item shopNames': data.shopNames,
            'Item categories': data.categoryNames,
            'Wallet Status': walletStatus,
            'Payment type': paymentMethod.name,
            Time: new Date().toLocaleString(),
        };

        const response = await placeOrder();
        if (response?.status) {
            // send algolia events here for all products
            // all cartItems
            aa('convertedObjectIDs', {
                userToken: userId,
                index: productIndex,
                eventName: 'Products Purchased',
                // queryID: 'cba8245617aeace44',
                objectIDs: [...itemsToCheckout?.map((item) => item.id || item.productId)],
            });

            let paymentDetail = {
                orderId: response.order.id,
                orderNumber: response.order.orderNumber,
                partnerCode: '',
                payment: {
                    deliveryCharge: response.order.deliveryFee,
                    email: response.order.email,
                    message: '',
                    paymentMethod: paymentMethod.enum,
                    redirecturl: '',
                    reference: null,
                    response: '',
                    responseString: '',
                    serviceCharge: 0, //serviceCharge,
                    status: '',
                    subTotal: response.order.subTotal,
                    total: totalPrice,
                    transactionId: null,
                    transactionReference: null,
                },
                referralCode: '',
            };
            setLoaderText('Preparing your payment');

            // check what each payment enum corresonds to here => src\views\actions\merchbuy\shopping-cart\payment\index.js
            if (response.paymentType === 2) {
                const config = {
                    email: user.email,
                    firstname: user.firstName,
                    lastname: user.lastName,
                    amount: Math.ceil(priceInfo.totalPrice * 100),
                    publicKey: paystackKey,
                };
                initializePayment(
                    config,
                    async (reference) => {
                        paymentDetail = {
                            ...paymentDetail,
                            payment: {
                                ...paymentDetail.payment,
                                reference: reference.reference,
                                transactionId: reference.trans,
                                transactionReference: reference.trxref,
                            },
                        };
                        //Save transaction reference
                        const orderNo = await dispatch(
                            merchbuyActions.sendPaymentDetails(paymentDetail, mixpanelPayload),
                        );
                        if (orderNo) {
                            successfulOrder(orderNo);
                        }
                    },
                    () => console.log('payment closed'),
                );
            } else if (response.paymentType === 3) {
                const mixpanelPayload = {
                    'Payment type': 'Wallet',
                    'Total order value': totalPrice,
                    'Item Category': productCategory,
                    'Total item count': totalQuantity,
                    'Total unique items count': itemsToCheckout?.length,
                    'Shop name list': shopNameList,
                    'Shop ID list': shopIDs,
                    'Total shop': shopNameList.length,
                    'Item ID list': productIDList,
                    'Item name list': productNameList,
                    'Wallet balance': walletBalance,
                };
                const params = {
                    ...response,
                    totalPrice,
                };
                const orderNo = await dispatch(merchbuyActions.payWithWallet(params, mixpanelPayload));
                if (orderNo) {
                    successfulOrder(orderNo);
                } else {
                    failedPayment();
                }
            } else {
                const params = {
                    narration: `Payment for Order`,
                    address: fullAddress,
                    orderList: [
                        {
                            amount_due: totalPrice,
                            currency: 'NGN',
                            order_id: response?.order?.id,
                            order_status: 'string',
                            retailer_id: 'string',
                        },
                    ],
                    payWithTransferType: 'OnDelivery',
                    totalAmount: totalPrice,
                };
                const payWithTransferResponse = await dispatch(merchbuyActions.payWithTransfer(params));
                if (payWithTransferResponse.status) {
                    const { data: accountDetails } = payWithTransferResponse?.data;
                    const paymentDetailsParams = {
                        ...paymentDetail,
                        payment: {
                            ...paymentDetail.payment,
                            reference: accountDetails?.reference,
                            transactionReference: accountDetails?.reference,
                        },
                        bankDetails: {
                            accountNumber: accountDetails?.accountNumber,
                            accountName: accountDetails?.accountName,
                            bankName: accountDetails?.bankName,
                        },
                    };
                    const orderNo = await dispatch(
                        merchbuyActions.sendPaymentDetails(paymentDetailsParams, mixpanelPayload),
                    );
                    if (orderNo) {
                        successfulOrder(orderNo);
                    }
                } else {
                    failedPayment();
                }
            }
        } else {
            failedOrder();
        }
    };

    const handleBankTransfer = async () => {
        const response = await placeOrder();
        if (response.status) {
            // send algolia events here for all products
            // all cartItems
            aa('convertedObjectIDs', {
                userToken: userId,
                index: productIndex,
                eventName: 'Products Purchased',
                // queryID: 'cba8245617aeace44',
                objectIDs: [...itemsToCheckout?.map((item) => item.id)],
            });

            const params = {
                address: fullAddress,
                narration: 'Payment for Order',
                orderList: [
                    {
                        amount_due: totalPrice,
                        currency: 'NGN',
                        order_id: response?.order?.id,
                        order_status: 'string',
                        retailer_id: 'string',
                    },
                ],
                payWithTransferType: 'Transfer',
                totalAmount: totalPrice,
            };
            setLoaderText('Preparing your payment');
            const payWithTransferResponse = await dispatch(merchbuyActions.payWithTransfer(params));
            if (payWithTransferResponse.status) {
                const { data: accountDetails } = payWithTransferResponse?.data;
                setBankName(accountDetails.bankName);
                setAccountNumber(accountDetails.accountNumber);
                setOrderNumber(response.order.orderNumber);
                setOrderId(response.order.id);
                setShowBankTransfer(true);
                setTransferReference(accountDetails?.reference);
            } else {
                failedPayment();
            }
        } else {
            failedOrder();
        }
    };

    const savePaymentDetailsForBankTransfer = async () => {
        const data = getProductInfoForMixPanel(itemsToCheckout);
        const mixpanelPayload = {
            'Total cart value': subTotal,
            'Total item count': data.totalItemCount,
            'Total unique item': itemsToCheckout?.length,
            'Total shops': data.sortedItems.length,
            'Item ids': data.productIds,
            'Item names': data.productNames,
            'Item shopNames': data.shopNames,
            'Item categories': data.categoryNames,
            'Wallet Status': walletStatus,
            'Payment type': paymentMethod.name,
            Time: new Date().toLocaleString(),
        };

        let paymentDetail = {
            orderId: orderId,
            orderNumber: orderNumber,
            partnerCode: '',
            payment: {
                deliveryCharge: deliveryFee,
                email: user.email,
                message: '',
                paymentMethod: paymentMethod.enum,
                redirecturl: '',
                reference: transferReference,
                response: '',
                responseString: '',
                serviceCharge: 0,
                status: '',
                subTotal: subTotal,
                total: totalPrice,
                transactionId: null,
                transactionReference: transferReference,
            },
            referralCode: '',
        };

        const orderNo = await dispatch(merchbuyActions.sendPaymentDetails(paymentDetail, mixpanelPayload));
        if (orderNo) {
            successfulOrder(orderNo);
        }
    };

    const successfulOrder = (orderNumber) => {
        const reOrder = cartItems.find((data) => data.fromReorder);
        if (reOrder) {
            mixPanel.track(REORDER_COMPLETE, {
                'Product Name': reOrder?.productName,
                'Product Id': reOrder?.id || reOrder?.productId,
                'Product Quantity': reOrder?.quantity,
                'Product Unit price': reOrder?.maxPrice,
            });
        }
        setOrderNumber(orderNumber);
        setStatusEnum(0);
        setStep(orderStatus.placed);
        if (fromBuyNow) {
            mixPanel.track(COMPLETE_INSTANT_PURCHASE, {
                'Product Quantity': itemsToCheckout[0]?.quantity,
                'Product name': itemsToCheckout[0]?.name || itemsToCheckout[0]?.productName,
                'Product ID': itemsToCheckout[0]?.id || itemsToCheckout[0]?.productId,
                'Product unit price': itemsToCheckout[0]?.bulkPrices[0]?.price,
                'Product volume price pair': totalPrice,
            });
        }
    };

    const failedOrder = () => {
        setStatusEnum(1);
        setStep(orderStatus.placed);
    };

    const failedPayment = () => {
        setStatusEnum(2);
        setStep(orderStatus.placed);
    };

    useEffect(() => {
        if (shouldPlaceOrder) {
            setShouldPlaceOrder(false);
            placeOrderHook();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldPlaceOrder, setShouldPlaceOrder]);

    if (processingOrder || loading) return <Loader text={loaderText} />;
    return (
        <Fragment>
            <DesktopBackgroundLayout>
                {showShippingPopup && (
                    <ShippingCompany
                        carts={itemsToCheckout || []}
                        getShippingDetails={(values) => {
                            setShowShippingPopup(false);
                            setShippingDetails(values);
                        }}
                        deliveryDetails={deliveryDetails}
                        totalQuantity={totalQuantity}
                        close={() => {
                            setShowShippingPopup(false);
                        }}
                    />
                )}
                {openFeatureFixes && <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />}
                {showAddressPopup && (
                    <DeliveryAddressList
                        merchbuyActions={merchbuyActions}
                        getDeliveryDetails={(values) => setDeliveryDetails(values)}
                        close={() => setShowAddressPopup(false)}
                        getAnyNewShopName={getAnyNewShopName}
                    />
                )}
                {showEditPopup && (
                    <EditDeliveryAddressDetails
                        open={showEditPopup}
                        cancel={() => setShowEditPopup(false)}
                        close={() => setShowAddressPopup(false)}
                        deliveryLocation={
                            deliveryDetails
                                ? { ...deliveryDetails, contactType: 'SELF' }
                                : { ...userDeliveryDetails, contactType: 'SELF' }
                        }
                        getDeliveryDetails={(values) => {
                            dispatch(merchbuyActions.updateDeliveryLocation(values));
                            setDeliveryDetails(values);
                        }}
                        setSelectedDeliveryInformation={(values) => {
                            setDeliveryDetails(values);
                        }}
                    />
                )}

                {showPin && (
                    <CheckoutWithWalletPin
                        amount={totalPrice}
                        setShowPin={setShowPin}
                        setShouldPlaceOrder={setShouldPlaceOrder}
                    />
                )}

                {showBankTransfer && (
                    <CheckoutWithBankTranfer
                        amount={totalPrice}
                        setShowBankTransfer={setShowBankTransfer}
                        bankName={bankName}
                        accountNumber={accountNumber}
                        orderId={orderId}
                        savePayment={savePaymentDetailsForBankTransfer}
                        failedPayment={failedPayment}
                    />
                )}

                {!showShippingPopup && !showAddressPopup && !showEditPopup && !showPin && !showBankTransfer && (
                    <Fragment>
                        <TopHeader
                            backLink="#"
                            backAction={backAction}
                            title="Order Confirmation"
                            noArrow={step === orderStatus.placed ? true : false}
                            withSpacesHeader
                        ></TopHeader>
                        <ScreenContainer color={colors.lightestGray} padding={'0'}>
                            <ModifiedContainer>
                                <Space color={colors.white} height={'13px'} />
                                {openPayment && (
                                    <Payment
                                        setPaymentMethod={setPaymentMethod}
                                        priceInfo={priceInfo}
                                        setStep={setStep}
                                        deliveryDetails={deliveryDetails}
                                        setOpenPayment={setOpenPayment}
                                        openPayment={openPayment}
                                        step={step}
                                        setOpenFeatureFixes={setOpenFeatureFixes}
                                    />
                                )}
                                {step === orderStatus.placed && (
                                    <SuccessfulOrder
                                        orderNumber={orderNumber}
                                        paymentMethod={paymentMethod}
                                        priceInfo={priceInfo}
                                        statusEnum={statusEnum}
                                        setStep={setStep}
                                    />
                                )}
                                {step === orderStatus.delivery && (
                                    <Fragment>
                                        <>
                                            <PageSubSectionsTop
                                                padding={'16px 16px 8px'}
                                                margin={'14px 0 0'}
                                                bgColor={colors.white}
                                            >
                                                <PageSubSectionsTopLeftTitle left="0" font="12px">
                                                    DELIVERY ADDRESS
                                                </PageSubSectionsTopLeftTitle>
                                                {deliveryDetails && (
                                                    <ChangeDeliveryText
                                                        onClick={() => {
                                                            if (!isAgent) {
                                                                setShowEditPopup(true);
                                                            } else {
                                                                setShowAddressPopup(true);
                                                            }
                                                        }}
                                                    >
                                                        CHANGE
                                                    </ChangeDeliveryText>
                                                )}
                                            </PageSubSectionsTop>
                                            {showFailedLoader && <CheckoutFailedLoader reload={reload} />}
                                            {showLoader && <CheckoutLoader />}
                                            {deliveryDetails && !showFailedLoader && !showLoader && (
                                                <DeliveryDetailsWrapper>
                                                    <InLineContainer>
                                                        {getShopName(deliveryDetails) && (
                                                            <UserAcronymn>{`${toAbbr(
                                                                getShopName(deliveryDetails),
                                                            )}`}</UserAcronymn>
                                                        )}
                                                        {getShopName(deliveryDetails) && (
                                                            <CardShopName>
                                                                {toTitleCase(getShopName(deliveryDetails))}
                                                            </CardShopName>
                                                        )}
                                                    </InLineContainer>
                                                    {getShopName(deliveryDetails) ? (
                                                        <CardUserName>{getName(deliveryDetails)}</CardUserName>
                                                    ) : (
                                                        <CardUserName margin="1% 0 0 0">
                                                            {getName(deliveryDetails)}
                                                        </CardUserName>
                                                    )}
                                                    {getShopName(deliveryDetails) ? (
                                                        <Text color={colors.lightDark} font="12px">
                                                            {getShopAddress(deliveryDetails)}
                                                        </Text>
                                                    ) : (
                                                        <Text
                                                            color={colors.lightDark}
                                                            font="12px"
                                                            style={{ marginTop: 25 }}
                                                        >
                                                            {getShopAddress(deliveryDetails)}
                                                        </Text>
                                                    )}
                                                    <Text color={colors.lightDark}>
                                                        {`${
                                                            upperCaseToTitleCase(deliveryDetails?.city) ||
                                                            upperCaseToTitleCase(deliveryDetails?.city)
                                                        }, ` || ''}
                                                        {`${
                                                            deliveryDetails?.lga || deliveryDetails?.localGovt
                                                        } L.G.A.` || ''}
                                                    </Text>
                                                    {/* <Text color={colors.lightDark}>
                                                            
                                                        </Text> */}
                                                </DeliveryDetailsWrapper>
                                            )}

                                            {!deliveryDetails && (
                                                <ButtonOuterWrapper>
                                                    <Button
                                                        top="0"
                                                        margin="1em 1em 0 1em"
                                                        backgroundColor={colors.white}
                                                        size="16px"
                                                        color={colors.deepBlue}
                                                        border={`1px solid ${colors.deepBlue}`}
                                                        onClick={() => {
                                                            if (!isAgent) {
                                                                setShowEditPopup(true);
                                                            } else {
                                                                setShowAddressPopup(true);
                                                            }
                                                        }}
                                                    >
                                                        Provide a delivery address
                                                    </Button>
                                                </ButtonOuterWrapper>
                                            )}

                                            {paymentMethod && (
                                                <Fragment>
                                                    <PageSubSectionsTop
                                                        padding={'12px 16px 8px'}
                                                        bgColor={colors.white}
                                                        margin={'8px 0 0'}
                                                    >
                                                        <PageSubSectionsTopLeftTitle left="0" font="12px">
                                                            PAYMENT METHOD
                                                        </PageSubSectionsTopLeftTitle>
                                                        <ChangeDeliveryText onClick={() => setOpenPayment(true)}>
                                                            CHANGE
                                                        </ChangeDeliveryText>
                                                    </PageSubSectionsTop>
                                                    <DetailsSection height="56px">
                                                        <PaymentWrapper
                                                            justify="flex-start"
                                                            align={
                                                                paymentMethod?.name === 'Sabi Wallet' ? null : 'center'
                                                            }
                                                        >
                                                            {paymentMethod?.icon}
                                                            <PaymentDetailsWrap>
                                                                <Text font="14px" color={colors.boldDark}>
                                                                    {paymentMethod?.name}
                                                                </Text>
                                                                {paymentMethod?.name === 'Sabi Wallet' && (
                                                                    <Text
                                                                        color={colors.themeTextColor11}
                                                                        align={'start'}
                                                                    >
                                                                        Balance: {walletBalance}
                                                                    </Text>
                                                                )}
                                                            </PaymentDetailsWrap>
                                                        </PaymentWrapper>
                                                    </DetailsSection>
                                                </Fragment>
                                            )}
                                            {!paymentMethod && (
                                                <Fragment>
                                                    <PageSubSectionsTop
                                                        padding={'16px 16px 8px'}
                                                        margin={'8px 0 0'}
                                                        bgColor={colors.white}
                                                    >
                                                        <PageSubSectionsTopLeftTitle left="0" font="12px">
                                                            PAYMENT METHOD
                                                        </PageSubSectionsTopLeftTitle>
                                                    </PageSubSectionsTop>
                                                    <ButtonOuterWrapper>
                                                        <Button
                                                            top="0"
                                                            margin="1em 1em 0 1em"
                                                            backgroundColor={colors.white}
                                                            size="16px"
                                                            color={colors.deepBlue}
                                                            border={`1px solid ${colors.deepBlue}`}
                                                            onClick={() => setOpenPayment(true)}
                                                        >
                                                            Choose a payment method
                                                        </Button>
                                                    </ButtonOuterWrapper>
                                                </Fragment>
                                            )}
                                        </>

                                        {/* {!paymentMethod && ( */}

                                        <PageSubSectionsTop
                                            padding={'16px 0 0 16px'}
                                            margin={'8px 0 0'}
                                            bgColor={colors.white}
                                        >
                                            <PageSubSectionsTopLeftTitle bottom="16px" left="0" font="12px">
                                                DELIVERY NOTE
                                            </PageSubSectionsTopLeftTitle>
                                        </PageSubSectionsTop>
                                        <Formik>
                                            {({ values }) => {
                                                return (
                                                    <Form>
                                                        <DeliveryWrapper>
                                                            <TextareaWithLabel
                                                                radius="4px"
                                                                type={'text'}
                                                                width="92%"
                                                                left="10px"
                                                                height="56px"
                                                                right="1em"
                                                                mtop="0px"
                                                                overflow="hidden"
                                                                border={`1px solid ${colors.lightGray}`}
                                                                holderColor={colors.themeTextColor11}
                                                                value={deliveryNote}
                                                                placeholder={'Enter here'}
                                                                bottom="8px"
                                                                name="deliveryInstruction"
                                                                onChange={(e) => {
                                                                    setDeliveryNote(e.target.value);
                                                                }}
                                                            />
                                                        </DeliveryWrapper>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>

                                        {/* <List noBorderBottom={false}> */}
                                        {/* {!shippingDetails && (
                                                <Fragment>
                                                    <ButtonOuterWrapper>
                                                        <Button
                                                            top="0"
                                                            margin="1em 1em 0 1em"
                                                            backgroundColor={colors.white}
                                                            size="16px"
                                                            color={colors.deepBlue}
                                                            border={`1px solid ${colors.deepBlue}`}
                                                            onClick={() => {
                                                                setShowShippingPopup(true);
                                                            }}
                                                        >
                                                            Choose a delivery method
                                                        </Button>
                                                    </ButtonOuterWrapper>
                                                </Fragment>
                                            )} */}
                                        {/* </List> */}
                                        <PaymentInfo
                                            priceInfo={priceInfo}
                                            deliveryDetails={deliveryDetails}
                                            showLoader={showLoader}
                                            showFailedLoader={showFailedLoader}
                                            showDeliveryFee={showDeliveryFee}
                                            totalPrice={totalPrice}
                                        />
                                        <Fragment>
                                            <PageSubSectionsTop padding={'16px 16px 6px'}>
                                                <PageSubSectionsTopLeftTitle left="0" font="12px">
                                                    ORDER SUMMARY
                                                </PageSubSectionsTopLeftTitle>
                                            </PageSubSectionsTop>
                                            <ProductWrapper>
                                                <Products carts={itemsToCheckout} />
                                            </ProductWrapper>
                                        </Fragment>
                                        <FooterWrapper>
                                            <TotalWrapper>
                                                <Text align={'left'} margin={'0 0 5px 0'}>
                                                    Total Amount Due
                                                </Text>
                                                <Text align={'left'} font={'16px'} fontWeight={'700'}>
                                                    {formatPrice(totalPrice)}
                                                </Text>
                                            </TotalWrapper>
                                            <RippleButton
                                                type="submit"
                                                top="12px"
                                                width={'50%'}
                                                size="16px"
                                                onClick={() => {
                                                    if (paymentMethod.enum === 3) {
                                                        setShowPin(true);
                                                    } else if (paymentMethod.enum === 5) {
                                                        handleBankTransfer();
                                                    } else {
                                                        placeOrderHook();
                                                    }
                                                }}
                                                disabled={!showContinueButton}
                                            >
                                                Pay Now
                                            </RippleButton>
                                        </FooterWrapper>
                                    </Fragment>
                                )}
                            </ModifiedContainer>
                        </ScreenContainer>
                    </Fragment>
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default Checkout;
