import React, { Fragment } from 'react';

import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Title } from '../../../../containers/MessageContainer';
import { merchbuyActions } from '../../../../redux/ducks/applications/merchbuy/actions';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { toTitleCasePerWord } from '../../../../utils/toTitleCase';
import { is_url } from '../../../../utils/urls/isUrl';
import { ReactComponent as LikeIcon } from '../assets/like.svg';
import { ReactComponent as LikedIcon } from '../assets/like_red.svg';
import ProductFallbackImage from '../assets/productFallbackImage.png';
// import { ReactComponent as ShareIcon } from '../assets/share-icon.svg';
import { OOSEducation } from '../saved-items/educate';
import { Container, Description, ImageWrapper, MainProductImage, Space } from '../styles';

import {
    DescriptionWrapper,
    IconCircle,
    ModifiedBadge,
    MoqBodyText,
    MoqContainer,
    MoqHeaderText,
    MoqWrapper,
    PriceText,
    SectionContainer,
    ShareIconWrapper,
    ViewMore,
} from './styles';

const ProductAvailability = ({
    productData,
    productDetails,
    openProductSharePopup,
    setOpenProductSharePopup,
    setDoAnimate,
    isAnimate,
    setIsAnimate,
    location,
    showEducate,
    isLiked,
    setIsLiked,
    oosEducation,
    userEducate,
    setUserEducate,
    isProductAvailable,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { images, productDescription } = productDetails || {};

    const setProductEducation = () =>
        localStorage.setItem('OOSEducation', JSON.stringify({ ...oosEducation, productDetails: true }));

    // const handleShareLink = () => {
    //     setTimeout(() => {
    //         setOpenProductSharePopup(!openProductSharePopup);
    //         setDoAnimate(false);
    //     }, 300);
    // };

    const handleViewMore = () => {
        history.push({
            pathname: '/actions/merchbuy/description',
            state: productData,
        });
    };

    const handleLike = async (id, warehouseId) => {
        setIsAnimate(true);
        const params = {
            productId: id,
            warehouseId,
        };
        if (isLiked) {
            const res = await dispatch(merchbuyActions.deleteSavedItems(id));
            if (res) {
                setIsLiked(false);
                setIsAnimate(false);
            }
        } else {
            const isItemAdded = await dispatch(merchbuyActions.addSavedItems(params));
            if (isItemAdded) {
                setIsLiked(true);
                setIsAnimate(false);
            } else {
                setIsLiked(false);
                setIsAnimate(false);
            }
        }
    };

    return (
        <Fragment>
            <ImageWrapper justifyContent={'center'}>
                <ShareIconWrapper zIndex={userEducate && !showEducate ? '2000' : null}>
                    {/* <IconCircle right={'12px'} onClick={handleShareLink} display={userEducate ? 'none' : ''}>
                        <ShareIcon />
                    </IconCircle> */}
                    <IconCircle
                        className={isAnimate ? 'active' : ''}
                        zIndex={userEducate ? '2000' : '98'}
                        onClick={() =>
                            handleLike(productDetails?.id || productDetails?.productId, productData?.warehouseId)
                        }
                    >
                        {isLiked ? <LikedIcon /> : <LikeIcon />}
                    </IconCircle>
                </ShareIconWrapper>
                <MainProductImage
                    src={
                        is_url(productDetails?.image || images?.baseImageUrl)
                            ? images?.baseImageUrl || productDetails?.image
                            : ProductFallbackImage
                    }
                    onError={(e) => {
                        e.target.src = ProductFallbackImage;
                    }}
                    alt="product image"
                    width={'345px'}
                    height={'269px'}
                />
                {userEducate && !showEducate && isProductAvailable && (
                    <OOSEducation
                        bottom={'-150px'}
                        width={'373px'}
                        title={'Like:'}
                        text={'Create a list of items you like'}
                        setUserEducate={setUserEducate}
                        setSavedEducation={setProductEducation}
                        content={'flex-end'}
                        justifyContent={'flex-end'}
                        arrowTwo
                    />
                )}
            </ImageWrapper>

            <Container>
                <Description>
                    {toTitleCasePerWord(productDetails?.brandName || '')}{' '}
                    {toTitleCasePerWord(productDetails?.productName || '')} -{' '}
                    {toTitleCasePerWord(productDetails?.productVariantName || '')} -{' '}
                    <span style={{ color: '#05944F' }}>{location?.state?.unitValue || 'units'}</span>
                </Description>
                <PriceText>
                    {productDetails?.bulkPrices?.length > 0
                        ? formatPrice(productDetails?.bulkPrices[0]?.price)
                        : formatPrice(productDetails.price?.retail || productDetails.bulkPrices[0]?.price)}
                </PriceText>
            </Container>
            <Space />
            <ModifiedBadge bottom="0">
                <MoqWrapper>
                    <MoqContainer>
                        <MoqHeaderText>Quantity</MoqHeaderText>
                        <MoqHeaderText>Price</MoqHeaderText>
                    </MoqContainer>
                    {productDetails?.bulkPrices?.length > 0 &&
                        productDetails?.bulkPrices?.map((bulk, index) => {
                            const moq =
                                index + 1 < productDetails?.bulkPrices?.length
                                    ? `${bulk?.moq || bulk?.quantity}-${
                                          productDetails?.bulkPrices[index + 1]?.moq ||
                                          productDetails?.bulkPrices[index + 1]?.quantity
                                      } ${location?.state?.unitValue || 'units'}`
                                    : `>= ${bulk?.moq || bulk?.quantity} ${location?.state?.unitValue || 'units'}`;
                            const price = formatPrice(bulk?.price);
                            return (
                                <MoqContainer key={index}>
                                    <MoqBodyText>{moq}</MoqBodyText>
                                    <MoqBodyText weight={'500'}>{price}</MoqBodyText>
                                </MoqContainer>
                            );
                        })}
                </MoqWrapper>
            </ModifiedBadge>
            <Space />
            <div>
                <SectionContainer>
                    <Title left="16px" bottom="0" color="#718596" size="12px" weight="700" textAlign="left">
                        PRODUCT DESCRIPTION
                    </Title>
                </SectionContainer>
                <DescriptionWrapper>
                    {productDescription && (
                        <Description>
                            {parse(
                                `${productDescription?.substring(0, 30)}${
                                    productDescription?.length > 29 ? '...' : ''
                                }`,
                            )}
                        </Description>
                    )}
                    <ViewMore onClick={handleViewMore}>View more</ViewMore>
                </DescriptionWrapper>
            </div>
        </Fragment>
    );
};

export default ProductAvailability;
