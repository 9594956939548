import { Field } from 'formik';
import styled, { css } from 'styled-components';

import { colors, fonts } from '../styles';

export const InputBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || null};
    height: ${({ height }) => height || null};
`;

export const InputLabelBlock = styled.div`
    position: relative;
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '56px'};
    padding: ${({ padding }) => (padding ? padding : '16px')};
    padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '16px')};
    box-sizing: border-box;
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || '4px'};
    margin-right: ${({ right }) => right || '0px'};
    margin-left: ${({ left }) => left || '0px'};
    background-color: ${({ background }) => background || `${colors.border.default}`};
    border: ${({ border }) => border || '1px'} solid ${({ borderColor }) => borderColor || colors.lightGray};
    border-radius: 4px;
    transition: all 0.1s linear;

    &:focus-within {
        transition: border 0.1s ease-in-out;
        border: 2px solid ${colors.border.primary};

        ${({ error, valid }) =>
            valid === 'false' &&
            error &&
            css`
                &:not(:disabled) {
                    border: 2px solid ${colors.border.error};
                }
            `}
    }

    ${({ error, valid }) =>
        valid === 'false' &&
        error &&
        css`
            &:not(:disabled) {
                border: 2px solid ${colors.border.error};
            }
        `}

    &:not(:disabled) .form-error {
        font-family: ${fonts.main};
        font-size: 10px;
        color: ${colors.red};
        margin-bottom: 20px;
    }

    &:disabled .form-error {
        display: none;
    }

    &::placeholder,
    &:disabled {
        opacity: 0.5;
    }
    @media (max-width: 767px) {
        &:not(:disabled) .form-error {
            display: block;
            font-size: 11px;
            width: 100%;
            text-align: left;
        }
    }
`;

export const InputLabel = styled.label`
    font-size: 10px;
    position: absolute;
    top: 8px;
    height: 16px;
    letter-spacing: 0.01em;
    color: ${({ color }) => color || colors.subGrey};
    margin-left: ${({ countryselection }) => (countryselection ? '83px' : '0')};
    display: none;
    transition: all 0.1s linear;
`;

export const InputLabelContainer = styled.div`
    min-height: ${({ minHeight }) => minHeight || '76px'};
    box-sizing: border-box;
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || null};
    width: ${({ width }) => width || null};
`;

export const TextInputField = styled(Field)`
    border: none;
    padding: ${({ padding }) => (padding ? padding : '0')};
    height: ${({ height }) => height || '24px'};
    font-size: 16px;
    font-weight: 500;
    color: ${({ color }) => color || colors.boldDark};
    width: 100%;
    height: ${({ inputheight }) => inputheight || '24px'};
    position: relative;
    background-color: ${({ background }) => background || `${colors.border.default}`};
    outline: none;
    appearance: textfield;
    letter-spacing: ${({ letterSpacing }) => letterSpacing || '0'};
    &input::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ${({ nolabelmargin }) =>
        !nolabelmargin &&
        css`
            &:not(:placeholder-shown) {
                margin-top: ${({ mtop }) => mtop || '6px'};
            }
        `}

    &:not(:placeholder-shown) + label {
        display: block;
    }

    & + label {
        ${({ showerror }) =>
            showerror === 'true' &&
            css`
                color: ${colors.red};
            `};
    }

    &:focus + label {
        color: ${colors.deepBlue};

        ${({ showerror }) =>
            showerror === 'true' &&
            css`
                color: ${colors.red};
            `};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px ${colors.border.default} inset !important;
        box-shadow: 0 0 0 30px ${colors.border.default} inset !important;
    }
`;

export const TextareaInputBlock = styled(Field)`
    position: relative;
    width: 100%;
    font-size: 14px;
    border-radius: 4px;
    outline-color: transparent;
    background-color: ${({ background }) => background || `${colors.themeColor3}`};
    border: none;
    outline: none;
`;

export const FormMetaData = styled.div`
    color: ${({ showerror }) => (showerror ? colors.red : colors.lightDark)};
    font-family: ${fonts.main};
    font-size: 10px;
    margin-bottom: ${({ bottom }) => bottom || '20px'};
    margin-left: 16px;
    text-align: left;
`;
