import React from 'react';

import styled from 'styled-components';

import { colors } from '../../styles';
import DesktopBackgroundLayout from '../../views/DesktopBackgroundLayout';
import loaderSvg from '../loader/assets/new-loader.png';

import Checking from './assets/checkout-text.svg';
import Merchant from './assets/merchant_onboarding.png';
import Vector from './assets/sync.png';

const LoaderOverlay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 9999;
    background: #ffffff;
`;

const ReloadText = styled.div`
    color: ${colors.themeTextColor11};
    font-size: 12px;
    font-weight: 400;
    margin-left: 29px;
    padding: 23px 0 12px 0px;
`;

const MainOverlay = styled(LoaderOverlay)`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: #ffffff;
`;

const SearchingLoaderOverlay = styled(LoaderOverlay)`
    left: 25%;
    margin-top: 20px;
`;

const ImageStyle = styled.img`
    margin-top: -20px;
    margin-left: 80px;
`;

const NoHeaderContainer = styled.div`
    margin: 70% 0 0 20%;
`;

const Message = styled.h3`
    text-align: center;
    font-size: ${({ size }) => size || '14px'};
    font-weight: 400;
    color: ${({ color }) => color || '#071827'};
    margin-right: ${({ right }) => right || '4px'};
    margin-top: ${({ top }) => top || null};
    line-height: 21px;
    width: ${({ width }) => width || '100%'};
`;

const Searching = styled.div`
    display: flex;
    align-items: bottom;
`;

const Container = styled.div`
    height: ${({ height }) => height || '111px'};
    width: 376px;
    background-color: ${({ bg }) => bg || `${colors.gray4}`};
`;

const Text = styled.div`
    color: ${({ color }) => color || '#5C6F7F'};
    font-size: ${({ size }) => size || '12px'};
    font-weight: 400;
    margin: ${({ margin }) => margin || '29px'};
    padding: ${({ padding }) => padding || '5px 0 1px 59px'};
`;

const InLineContainer = styled.div`
    display: ${({ display }) => display || 'flex'};
    flex-direction: ${({ direction }) => direction || 'row'};
    align-items: ${({ center }) => center || 'center'};
`;

const ReloadButtonWrapper = styled.div`
    background-color: white;
    background-repeat: no-repeat;
    border: 1.6px solid ${colors.themeColor7};
    align-items: center;
    text-align: center;
    height: 40px;
    width: 40%;
    margin: auto;
    border-radius: 4px;
`;

const Div = styled.div`
    color: ${colors.themeTextColor11};
    font-size: 12px;
    font-weight: 400;
    margin: 1% 0 0 39%;
`;
const ReloadButton = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: ${colors.themeTextColor12};
    margin-left: 6%;
    padding-top: 10px;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const ImageContainer = styled.div`
    margin-left: 20%;
`;

export const Loader = ({ text = 'Loading', width }) => {
    return (
        <DesktopBackgroundLayout>
            <MainOverlay>
                <img src={loaderSvg} alt="Loading..." width="60" height="60"></img>
                <Searching>{text && <Message width={width}>{text}...</Message>}</Searching>
            </MainOverlay>
        </DesktopBackgroundLayout>
    );
};

export const SearchingLoader = () => {
    return (
        <SearchingLoaderOverlay>
            <img src={loaderSvg} alt="Loading..." width="60" height="60"></img>
            <ImageStyle src={Merchant} alt="Merchant" />
        </SearchingLoaderOverlay>
    );
};

export const MerchantNewDetailsLoader = ({ bg, height, width, margin, padding, size }) => {
    return (
        <Container bg={bg} width={width} height={height}>
            <InLineContainer>
                <img src={loaderSvg} alt="" width="60" height="60" style={{ margin: '-3% 2% 0 30%' }}></img>
                <Text margin={margin} padding={padding} size={size}>
                    Checking...
                </Text>
            </InLineContainer>
        </Container>
    );
};

export const LoaderWithoutHeader = ({ text = 'Loading', width }) => {
    return (
        <NoHeaderContainer>
            <img src={loaderSvg} alt="Loading..." width="60" height="60"></img>
            <Searching>
                {text && (
                    <Message right="20%" width={width}>
                        {text}...
                    </Message>
                )}
            </Searching>
        </NoHeaderContainer>
    );
};

export const MerchantLoader = ({ bg, height, width, margin, padding, size }) => {
    return (
        <Container bg={bg} width={width} height={height}>
            <InLineContainer>
                <img src={loaderSvg} alt="" width="50" height="50" style={{ margin: '8% 0 0 25%' }}></img>
                <Text margin={margin} padding={padding} size={size}>
                    Checking, please wait...
                </Text>
            </InLineContainer>
        </Container>
    );
};
export const CheckoutLoader = () => {
    return (
        <Container>
            <Flex>
                <img src={loaderSvg} alt="" width="60" height="60" style={{ margin: '3% 0 0 30%' }}></img>
                <img src={Checking} alt="Checking" style={{ margin: '1% 0 0 0' }} />
            </Flex>
            <Text margin="-9px 0 0 3px">We are confirming your delivery address.</Text>
            <Div>Please be patient.</Div>
        </Container>
    );
};

export const CheckoutFailedLoader = ({ reload }) => {
    return (
        <Container>
            <ReloadText>For some reasons, we could not determine your location.</ReloadText>
            <ReloadButtonWrapper onClick={() => reload()}>
                <InLineContainer>
                    <img src={Vector} alt="" style={{ marginLeft: '30%', paddingTop: '10px' }} />
                    <ReloadButton>Reload</ReloadButton>
                </InLineContainer>
            </ReloadButtonWrapper>
        </Container>
    );
};

export const SavedItemsLoader = ({ text = 'Getting your saved items', width }) => {
    return (
        <NoHeaderContainer>
            <ImageContainer>
                <img src={loaderSvg} alt="Loading..." width="120" height="120"></img>
            </ImageContainer>
            <Searching>
                {text && (
                    <Message top="-5%" right="20%" width={width}>
                        {text}...
                    </Message>
                )}
            </Searching>
        </NoHeaderContainer>
    );
};
