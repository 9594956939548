import React, { Fragment, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { TopHeader } from '../../../../../components';
import { FeatureUndergoingFixes } from '../../../../../components/popup/feature-undergoing-fixes';
import { MenuOptionLogo } from '../../../../../containers/MenuContainer';
import { ScreenContainer } from '../../../../../containers/ScreenContainer';
import { useReasonForBlockingUserWallet } from '../../../../../hooks/useReasonForBlockingUserWallet';
import { getPinTrialsLeft } from '../../../../../redux/ducks/account/wallet/actions';
import DesktopBackgroundLayout from '../../../../DesktopBackgroundLayout';
import { BlockedWalletPopUp } from '../../../transactions/wallet-transactions/blocked-wallet-popup';

import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import BankIcon from './assets/bank.svg';
import bankBg from './assets/bank_bg.svg';
import WalletBadge from './assets/wallet.svg';
import walletBg from './assets/wallet_bg.svg';

const Title = styled.h2`
    font-size: 16px;
    font-weight: 700;
    color: #071827;
    text-align: left;
    margin: 100px 16px 24px;
`;

const Wrapper = styled.div`
    display: flex;
    position: relative;
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
    align-items: center;
    padding: 20px;
    margin: 16px;
`;

const ArrowForward = styled(ArrowIcon)`
    position: absolute;
    color: ${({ color }) => color || '#227EFF'};
    right: 20px;
    top: 30px;
`;

const VerticalLayout = styled.div`
    flex: 1;
`;

const Text = styled.h2`
    font-size: ${({ size }) => size || '14px'};
    font-weight: 400;
    color: ${({ color }) => color || '#0350BB'};
    text-align: left;
    padding: 2px;
    margin: auto 16px;
`;

const ImageWrapper = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
`;

const WalletAccount = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [trailsLeft, setTrailsLeft] = useState('');
    const reasonForBlocking = useReasonForBlockingUserWallet();

    const getPinTrails = () => {
        dispatch(getPinTrialsLeft()).then((res) => {
            res && setTrailsLeft(res);
        });
    };
    useEffect(() => {
        getPinTrails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const features = useSelector((state) => state.user.features);
    const [openFeatureFixes, setOpenFeatureFixes] = useState(false);

    const clickAction = (url, feature) => {
        if (!features?.[feature]?.status) return setOpenFeatureFixes(true);
        history.push(url);
    };

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Transfer'} />
                {(trailsLeft?.pinTriesLeft === 0 || trailsLeft.status === 'BLOCKED') && (
                    <BlockedWalletPopUp showCancelIcon={false} reason={reasonForBlocking?.reason} />
                )}
                {openFeatureFixes && <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />}
                {openFeatureFixes && <FeatureUndergoingFixes cancel={() => setOpenFeatureFixes(false)} />}
                <ScreenContainer padding="0px">
                    <Title>Where would you like to transfer to?</Title>

                    <Wrapper
                        background={bankBg}
                        onClick={() => clickAction('/user/wallet_to_bank_list', 'walletToBank')}
                    >
                        <ImageWrapper>
                            <MenuOptionLogo icon={BankIcon} />
                        </ImageWrapper>
                        <VerticalLayout>
                            <Text>Bank</Text>
                            <Text size={'12px'} color={'#718596'}>
                                Transfer to a bank account
                            </Text>
                        </VerticalLayout>
                        <ArrowForward />
                    </Wrapper>

                    <Wrapper
                        background={walletBg}
                        onClick={() => clickAction('/user/wallet_to_wallet_list', 'walletToWallet')}
                    >
                        <ImageWrapper>
                            <MenuOptionLogo icon={WalletBadge} />
                        </ImageWrapper>
                        <VerticalLayout>
                            <Text color={'#7E42F5'}>Wallet</Text>
                            <Text size={'12px'} color={'#718596'}>
                                Transfer to another wallet
                            </Text>
                        </VerticalLayout>
                        <ArrowForward color={'#7E42F5'} />
                    </Wrapper>
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default WalletAccount;
