import React, { useState, useEffect, Fragment } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';

import { ComplaintDetails, InfoBadge } from '../../../../components';
import { Button, RippleButton } from '../../../../components/button';
import { TopHeader } from '../../../../components/header';
import { PageLogo } from '../../../../components/logo';
import { CancelButton, ConfirmButton } from '../../../../components/popup/common';
import FallbackProductImage from '../../../../components/popup/merchbuy-add-to-cart-popup/assets/productImage.svg';
import { BlurBackground } from '../../../../containers/BlurBackground';
import { ActionBlock } from '../../../../containers/OverlayContainer';
import { Container, ScreenContainer } from '../../../../containers/ScreenContainer';
import { getComplaintComments, getComplaintDetails } from '../../../../redux/ducks/applications/complaints/actions';
import { merchbuyActions } from '../../../../redux/ducks/applications/merchbuy/actions';
import { checkOrderSurvey, postSurvey } from '../../../../redux/ducks/user/actions/nps';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { parseDate } from '../../../../utils/date/parseDateFromStr';
import { LOG_COMPLAINT_START, REORDER_START } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import { toTitleCasePerWord } from '../../../../utils/toTitleCase';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import StarRating from '../../../home/help/rate-spaces/components/StarRating';
import { npsStarRates } from '../../../home/help/rate-spaces/utils';
import { RatingTextArea } from '../../../home/transactions/wallet-transactions/transaction-rating';
import SupermarketIcon from '../../my-shop/assets/supermarket.svg';
import { ColoredCircle } from '../../remittance/styles';
import { ReactComponent as BankIcon } from '../assets/bank.svg';
import { ReactComponent as CardIcon } from '../assets/debit_card.svg';
import { ReactComponent as MasterCardIcon } from '../assets/mastercard.svg';
import { ReactComponent as PodIcon } from '../assets/pay_on_delivery.svg';
import { ReactComponent as WalletIcon } from '../assets/spaces_wallet.svg';
import { CartProductCounter } from '../components/cart-counter';
import { Text, CircleText } from '../styles';

import { paymentType } from './paymentType';

const OrderNoInfoWrapper = styled.div`
    margin-top: 20px;
    background-color: ${colors.white};
    min-height: 53px;
    padding: 16px;
`;

const OrderInfoWrapper = styled.div`
    background-color: ${colors.white};
    min-height: 53px;
    padding: 16px;
`;

const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ bottom }) => bottom || '8px'};
    justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
    gap: ${({ gap }) => gap || '0'};
`;

const GridWrapper = styled.div`
    width: 100%;
`;

const Border = styled.div`
    height: 1px;
    background-color: ${colors.gray4};
    margin-top: ${({ top }) => top || '0'};
    margin-bottom: ${({ bottom }) => bottom || '0'};
`;

const Card = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => direction || 'column'};
    justify-content: ${({ justify }) => justify || null};
    padding: 16px;
    border-radius: 4px;
    margin: ${({ margin }) => margin || '16px 16px 0px'};
    background-color: ${colors.white};
`;

const OrderText = styled.div`
    color: ${({ color }) => color || colors.themeTxtColor10};
    font-weight: ${({ weight }) => weight || '400'};
    font-size: 12px;
    line-height: 18px;
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || null};
    margin-left: ${({ left }) => left || null};
`;

const ItemText = styled.div`
    color: ${({ color }) => color || colors.themeTxtColor10};
    font-weight: ${({ weight }) => weight || '400'};
    font-size: 14px;
    line-height: 21px;
    margin-top: ${({ top }) => top || null};
`;

const CenterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const ReorderItem = styled.div`
    background-color: #e9f2ff;
    height: 26px;
    color: #227eff;
    text-align: center;
    padding-top: 6px;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    &:hover {
        background-color: ${colors.lightGray};
    }
`;

const ProductBlock = styled.div`
    display: flex;
    width: 100%;
`;

const ProductImage = styled.img`
    width: 48.53px;
    height: 44px;
    margin-top: 0;
    margin-left: 5px;
    border-radius: 4px;
    object-fit: cover;
`;

const Title = styled.div`
    font-size: 12px;
    font-weight: 400;
    margin: 10px 0 2px;
    text-align: left;
`;

const MoqParagraph = styled.div`
    font-size: 10px;
    color: #718596;
    font-weight: 500;
    text-align: left;
    margin-top: ${({ top }) => top || null};
`;

const OuterQuantityHold = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const QuantityHold = styled.div`
    line-height: 18px;
    margin-left: 10px;
`;

const slideUp = keyframes`
 0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0%);
        }
`;

const slideDown = keyframes`
 0% {
            transform: translateY(0%);
        }
        100% {
            transform: translateY(100%);
        }
`;

const ShoppingOptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    padding: 10px 0;
    border-radius: 16px 16px 0 0;
    @media (min-width: 576px) {
        padding-bottom: 60px;
    }
    transition: opacity 0.5s linear 0s;
    animation: ${(props) =>
        !props.animate
            ? css`
                  ${slideUp} 0.3s ease-out
              `
            : css`
                  ${slideDown} 0.3s ease-out
              `};
`;

const TopText = styled.span`
    margin-top: 5px;
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    width: ${({ width }) => width || null};
    font-size: ${({ font }) => font || '12px'};
    color: ${({ color }) => color || null};
    float: left;
    margin-left: 16px;
`;

const ButtonWrapper = styled.div`
    padding: 0 16px;
`;

const RateBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: ${({ bottom }) => bottom || null};
    margin-top: ${({ top }) => top || null};
`;

const RateText = styled.h4`
    font-weight: 700;
    font-size: 16px;
    color: ${colors.boldDark};
    text-align: center;
    margin-bottom: ${({ bottom }) => bottom || null};
`;

const RateContainer = styled.div`
    padding: 0 16px;
    background: ${colors.lightestGray};
`;

const Backdrop = styled.div`
    height: 100%;
    width: 100%;
`;

const OrderDetails = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isB2BAgent = useSelector((state) => state.user.isB2BAgent);
    const orderStatus = useSelector((state) => state.user.orderSurveyStatus);
    const surveyTypes = useSelector((state) => state.user.surveyTypes);
    const orderHistory = useSelector((state) => state.applications.merchbuy.chosenOrderHistory);
    const currentLocation =
        localStorage.getItem('deliveryLocation') && JSON.parse(localStorage.getItem('deliveryLocation'));
    const surveyType = surveyTypes?.find((type) => type.title === 'ORDER SURVEY');

    const [orderItems, setOrderItems] = useState([]);
    const [selectedMoq, setSelectedMoq] = useState(orderItems.bulkPrices ? orderItems.bulkPrices[0].moq : 0);
    const [IDX, setIDEX] = useState(0);
    const [allDetails, setAllDetails] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [openRating, setOpenRating] = useState(false);
    const [showOrderOption, setShowOrderOption] = useState(false);
    const [complaintDetails, setComplaintDetails] = useState();
    const [rating, setRating] = useState(null);
    const [showValue, setShowValue] = useState('');
    const [altloading, setAltloading] = useState(false);
    const [content, setContent] = useState(null);
    const [doAnimate, setDoAnimate] = useState(false);
    const [complaintFiles, setComplaintFiles] = useState([]);
    const [comments, setComments] = useState([]);
    const departmentId = process.env.REACT_APP_UX_SUPPORT_DEPARTMENT_ID; //country specific
    const issueTitle = 'Orders';
    const category = 'Orders';
    const [score, setScore] = useState(0);
    const submitCriteria = score <= 30 && !content;

    useEffect(() => {
        setScore(npsStarRates.find((x) => x.value === showValue)?.score);
    }, [showValue]);

    const handleSubmit = () => {
        const params = {
            externalRef: orderHistory?.orderId,
            responded: true,
            surveyId: surveyType && surveyType.id,
            value1: score,
            value2: content,
        };
        setAltloading(true);
        dispatch(postSurvey(params)).then((res) => {
            if (res) {
                dispatch(checkOrderSurvey(orderHistory?.orderId)).then((res) => {
                    if (res) {
                        setAltloading(false);
                        setOpenRating(!openRating);
                    } else {
                        setAltloading(false);
                        setOpenRating(!openRating);
                    }
                });
            } else {
                setAltloading(false);
            }
        });
    };

    useEffect(() => {
        if (Object.entries(orderHistory).length) {
            const productVariantIds = orderHistory?.orderItems?.map((item) => item.productId);
            const params = {
                productVariantIds,
                warehouseId: orderHistory.orderItems[0].warehouseId,
            };
            dispatch(merchbuyActions.getProductDetailsById(params)).then((res) => {
                res && setAllDetails(res);
            });
        }
    }, [dispatch, orderHistory]);

    useEffect(() => {
        if (orderHistory?.orderNumber) {
            dispatch(getComplaintDetails(orderHistory?.orderNumber)).then((data) => {
                if (data) {
                    setComplaintDetails(data);
                } else setComplaintDetails({});
            });
        }

        if (orderHistory?.orderId) dispatch(checkOrderSurvey(orderHistory?.orderId));
    }, [dispatch, orderHistory]);

    useEffect(() => {
        if (itemDetails?.bulkPrices) setSelectedMoq(itemDetails?.bulkPrices[0]?.moq);
    }, [itemDetails]);

    useEffect(() => {
        setOrderItems(orderHistory?.orderItems || []);
    }, [orderHistory]);

    useEffect(() => {
        if (complaintDetails?.id) {
            dispatch(getComplaintComments(complaintDetails?.id)).then((data) => {
                const { ticketFiles, ticketComments } = data || {};
                if (ticketFiles?.length > 0 || ticketComments?.length > 0) {
                    setComplaintFiles(ticketFiles);
                    setComments([...ticketFiles, ...ticketComments]);
                } else {
                    return setComments([]);
                }
            });
        }
    }, [dispatch, complaintDetails]);

    const find = () => {
        const fill = orderItems?.map((x) => {
            const mapfill = x?.statusQuantities.filter(
                (item) => item.statusDesc === 'Delivered' || item.statusDesc === 'Cancelled',
            );
            return mapfill;
        });
        const fillFilter = fill.filter((x) => x.length > 0);
        return fillFilter;
    };

    const setPosition = (val) => {
        for (let i = 0; i < itemDetails?.bulkPrices.length; i++) {
            if (!itemDetails?.bulkPrices[i + 1]) {
                return setIDEX(i);
            } else if (val >= itemDetails?.bulkPrices[i]?.moq && val < itemDetails?.bulkPrices[i + 1]?.moq) {
                return setIDEX(i);
            }
        }
    };

    const imageHasRightExtension = (url) => {
        return !!url.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
    };

    const handleClose = () => {
        setDoAnimate(true);
        setTimeout(() => {
            setShowOrderOption(false);
        }, 300);
    };

    const navigateToLogComplaint = () => {
        history.push({
            pathname: '/actions/complaints/log_complaint',
            state: {
                reference: orderHistory?.orderNumber,
                departmentId,
                issueTitle,
                category,
            },
        });
    };

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                {showOrderOption && (
                    <BlurBackground position="fixed" zIndex={'10000'}>
                        <Backdrop onClick={handleClose} />
                        <ShoppingOptionWrapper animate={doAnimate}>
                            <TopText fontWeight="700" font-size="16px">
                                Reorder Item
                            </TopText>
                            <ProductBlock>
                                <OrderInfoWrapper>
                                    <FlexWrapper gap="13px" justifyContent="normal">
                                        <div>
                                            <ProductImage
                                                src={
                                                    imageHasRightExtension(
                                                        itemDetails?.images ? itemDetails?.images.baseImageUrl : '',
                                                    )
                                                        ? itemDetails?.images.baseImageUrl
                                                        : FallbackProductImage
                                                }
                                                alt="product image"
                                            />
                                        </div>
                                        <GridWrapper>
                                            <FlexWrapper bottom="14px">
                                                <Text fontWeight="400" color={colors.boldDark}>
                                                    {itemDetails?.productName}
                                                </Text>
                                            </FlexWrapper>
                                            <FlexWrapper bottom={'0px'}>
                                                <Text fontWeight="700">
                                                    {itemDetails?.bulkPrices &&
                                                        formatPrice(itemDetails?.bulkPrices[IDX]?.price || 0) +
                                                            '/ unit'}
                                                </Text>
                                            </FlexWrapper>
                                        </GridWrapper>
                                    </FlexWrapper>
                                </OrderInfoWrapper>
                            </ProductBlock>
                            <hr
                                style={{
                                    margin: '0px auto 16px',
                                    width: '95%',
                                    backgroundColor: colors.gray4,
                                    border: `solid 1px ${colors.gray4}`,
                                }}
                            />
                            <OuterQuantityHold>
                                <QuantityHold>
                                    <Title> Select Quantity</Title>
                                    <MoqParagraph top="2px">
                                        MINIMUM ORDER QUANTITY:{' '}
                                        {itemDetails?.bulkPrices[0]?.moq + ' ' + (itemDetails?.unitValue || 'Units')}
                                    </MoqParagraph>
                                </QuantityHold>
                                <CartProductCounter
                                    moq={itemDetails?.bulkPrices[0]?.moq}
                                    aoq={itemDetails?.quantity?.retail}
                                    isGroupBuy={false}
                                    selectedMoq={selectedMoq}
                                    setSelectedMoq={(val) => {
                                        setPosition(val);
                                        setSelectedMoq(val);
                                    }}
                                />
                            </OuterQuantityHold>
                            {(itemDetails?.quantity?.retail === 0 || selectedMoq > itemDetails?.quantity?.retail) && (
                                <InfoBadge message="This item cannot be reordered as it is currently out of stock" />
                            )}
                            <ActionBlock direction={'row'} bottom="40px" style={{ padding: '0 16px' }}>
                                <CancelButton
                                    style={{ width: '233px' }}
                                    type={'button'}
                                    onClick={handleClose}
                                    border
                                    color={colors.deepBlue}
                                    weight="500"
                                >
                                    Cancel
                                </CancelButton>
                                <ConfirmButton
                                    disabled={
                                        itemDetails?.quantity?.retail === 0 ||
                                        selectedMoq > itemDetails?.quantity?.retail
                                    }
                                    onClick={() => {
                                        itemDetails.quantity = selectedMoq;
                                        itemDetails.productId = itemDetails?.id;
                                        itemDetails.shopId = itemDetails?.branchId;
                                        itemDetails.name = itemDetails?.productName;
                                        itemDetails.maxPrice = itemDetails?.bulkPrices[0]?.price;
                                        setShowOrderOption(false);
                                        dispatch(merchbuyActions.addProductToCart(itemDetails, false, false));
                                    }}
                                    bgColor={colors.deepBlue}
                                    color={colors.white}
                                    weight="500"
                                >
                                    Add to Cart
                                </ConfirmButton>
                            </ActionBlock>
                        </ShoppingOptionWrapper>
                    </BlurBackground>
                )}
                <TopHeader withSpacesHeader title={!openRating ? 'Orders' : 'Rate Your Experience'}></TopHeader>
                <ScreenContainer color={colors.lightestGray} top="10px" padding="50px 0px">
                    <Card>
                        <OrderText>Order No. {orderHistory?.orderNumber}</OrderText>
                        <FlexWrapper>
                            <OrderText weight="700">
                                {formatPrice(orderHistory?.financialDetails?.totalPrice)}
                            </OrderText>
                            <OrderText color={colors.subGrey}>x{orderHistory?.numberOfItems} Items</OrderText>
                        </FlexWrapper>
                    </Card>

                    {find()?.length > 0 && !orderStatus && !openRating && (
                        <ButtonWrapper onClick={() => setOpenRating(!openRating)}>
                            <RippleButton
                                height={'37px'}
                                backgroundColor={colors.white}
                                color={colors.deepBlue}
                                border={`1px solid ${colors.deepBlue}`}
                                top={'16px'}
                            >
                                Leave Feedback
                            </RippleButton>
                        </ButtonWrapper>
                    )}
                    {openRating && (
                        <RateContainer>
                            <RateBox bottom={'58px'} top={'50px'}>
                                <RateText bottom={'16px'}>How satisfied were you with making this order?</RateText>
                                <div style={{ padding: '0 20px' }}>
                                    <StarRating
                                        rating={rating}
                                        setRating={setRating}
                                        showValue={showValue}
                                        setShowValue={setShowValue}
                                    />
                                </div>
                            </RateBox>
                            <hr style={{ background: `${colors.headerBorder}` }} />
                            {(showValue === 'Poor' || showValue === 'Bad') && (
                                <RateBox bottom={'20px'}>
                                    <RatingTextArea
                                        value={content}
                                        onChange={(event) => setContent(event.target.value)}
                                        type={'text'}
                                        placeholder={'Tell us how we can improve'}
                                        name={'description'}
                                    />
                                </RateBox>
                            )}
                            <RippleButton
                                height={'56px'}
                                top={'40%'}
                                onClick={() => handleSubmit()}
                                disabled={!showValue || altloading || submitCriteria}
                            >
                                {altloading ? 'Submitting...' : 'Submit'}
                            </RippleButton>
                        </RateContainer>
                    )}
                    {!openRating && (
                        <div>
                            <OrderText
                                weight="700"
                                top={find().length > 0 && !orderStatus ? '43px' : '16px'}
                                left="16px"
                                color={colors.subGrey}
                            >
                                ITEMS IN ORDER
                            </OrderText>
                            {orderItems.map((x, index) => {
                                const retailInfo = allDetails?.find((y) => y.id === x.productId);
                                return (
                                    <OrderNoInfoWrapper key={index}>
                                        <FlexWrapper gap="10px" justifyContent="normal">
                                            <div
                                                onClick={() => {
                                                    history.push({
                                                        pathname: `/actions/merchbuy/product/${x?.productId}`,
                                                        state: {
                                                            fromOrderHistory: true,
                                                            productId: x?.productId,
                                                            productName: x?.productName,
                                                            warehouseId: x?.warehouseId,
                                                        },
                                                    });
                                                    dispatch(merchbuyActions.setInitProductDetials(x));
                                                }}
                                            >
                                                <PageLogo
                                                    Icon={x.productImageUrl}
                                                    fallback={SupermarketIcon}
                                                    width={'44px'}
                                                    height={'44px'}
                                                    iconHeight={'44px'}
                                                    iconWidth={'44px'}
                                                    background={'transparent'}
                                                    borderRadius="4px"
                                                />
                                            </div>
                                            <GridWrapper>
                                                <FlexWrapper bottom="14px">
                                                    <Text fontWeight="400" color={colors.boldDark}>
                                                        {`${toTitleCasePerWord(x?.brandName)} ${toTitleCasePerWord(
                                                            x.productName,
                                                        )} - ${toTitleCasePerWord(x?.productVariantName)}`}
                                                    </Text>
                                                </FlexWrapper>
                                                <FlexWrapper>
                                                    <Text fontWeight="700" color={colors.boldDark}>
                                                        {formatPrice(x.unitPrice)}
                                                    </Text>
                                                    <Text fontWeight="400" color={colors.subGrey}>
                                                        X{x.quantity} Items
                                                    </Text>
                                                </FlexWrapper>
                                            </GridWrapper>
                                        </FlexWrapper>
                                        {x?.statusQuantities &&
                                            x?.statusQuantities.map((status, index) => (
                                                <FlexWrapper key={index}>
                                                    <CircleText size="10px" lineHeight="16px">
                                                        <ColoredCircle
                                                            status={(status.statusDesc || '').toUpperCase()}
                                                        />
                                                        {`${status.statusDesc}: ${status.quantity} unit(s)`}
                                                    </CircleText>
                                                </FlexWrapper>
                                            ))}
                                        <Border top="8px" bottom="8px" />

                                        {retailInfo?.quantity?.retail > retailInfo?.bulkPrices[0]?.moq &&
                                            retailInfo?.listingOptions?.isOnMerchBuy &&
                                            retailInfo?.state === currentLocation.state && (
                                                <ReorderItem
                                                    onClick={async () => {
                                                        mixPanel.track(REORDER_START, {
                                                            'Product Name': retailInfo?.productName,
                                                            'Product Id': retailInfo?.id,
                                                        });
                                                        setItemDetails({ ...retailInfo, fromReorder: true });
                                                        setShowOrderOption(true);
                                                        setDoAnimate(false);
                                                    }}
                                                >
                                                    Re-order Item
                                                </ReorderItem>
                                            )}
                                    </OrderNoInfoWrapper>
                                );
                            })}
                            <Card margin="16px 0">
                                <FlexWrapper top="0px">
                                    <OrderText color={colors.smoothGreyText}>Items Total</OrderText>
                                    <ItemText>{formatPrice(orderHistory?.financialDetails?.subTotal)}</ItemText>
                                </FlexWrapper>
                                <FlexWrapper top="8px">
                                    <OrderText color={colors.smoothGreyText}>Delivery Fee</OrderText>
                                    <ItemText>{formatPrice(orderHistory?.financialDetails?.deliveryCharge)}</ItemText>
                                </FlexWrapper>
                                <FlexWrapper top="8px">
                                    <OrderText weight="500" color={colors.lightGray2}>
                                        TOTAL
                                    </OrderText>
                                    <ItemText weight="700" color={colors.themeTextColor1}>
                                        {formatPrice(orderHistory?.financialDetails?.totalPrice)}
                                    </ItemText>
                                </FlexWrapper>
                                <CenterWrapper>
                                    <Border top="16px" bottom="8px" />
                                    <OrderText
                                        color={colors.deepBlue}
                                        onClick={() => history.push('/actions/merchbuy/account/commission')}
                                    >
                                        See commission breakdown
                                    </OrderText>
                                </CenterWrapper>
                            </Card>

                            <Card direction="row" margin="16px 0" justify="space-between">
                                <ItemText color={colors.lightDark}>Delivered Value</ItemText>
                                <ItemText>{formatPrice(orderHistory?.financialDetails?.deliveredValue)}</ItemText>
                            </Card>

                            <Card direction="row" margin="16px 0" justify="space-between">
                                <ItemText color={colors.lightDark}>Order placed by</ItemText>
                                <ItemText>{orderHistory?.placedBy?.name}</ItemText>
                            </Card>

                            <OrderText weight="700" top="16px" left="16px" color={colors.subGrey}>
                                CUSTOMER
                            </OrderText>

                            <Card direction="row" margin="4px 0" justify="space-between">
                                <ItemText>
                                    {`${orderHistory?.orderDelivery?.firstName} ${orderHistory?.orderDelivery?.lastName}`}
                                </ItemText>
                                <ItemText color={colors.deepBlue}>View Details</ItemText>
                            </Card>

                            <OrderText weight="700" top="16px" left="16px" color={colors.subGrey}>
                                DELIVERY ADDRESS
                            </OrderText>

                            <Card margin="4px 0">
                                <ItemText>
                                    {`${orderHistory?.orderDelivery?.firstName} ${orderHistory?.orderDelivery?.lastName}`}
                                </ItemText>
                                <OrderText top="8px" color={colors.lightDark}>
                                    {`${orderHistory?.orderDelivery?.address}`}
                                </OrderText>
                                <OrderText
                                    color={colors.lightDark}
                                >{`${orderHistory?.orderDelivery?.lga} L.G.A`}</OrderText>
                                <OrderText
                                    color={colors.lightDark}
                                >{`${orderHistory?.orderDelivery?.state} State.`}</OrderText>
                            </Card>

                            <OrderText weight="700" top="16px" left="16px" color={colors.subGrey}>
                                PAYMENT METHOD
                            </OrderText>
                            <Card margin="4px 0">
                                <>
                                    <FlexWrapper>
                                        <FlexWrapper>
                                            <Text size="14px" margin={'0 11px 0 0'}>
                                                {orderHistory?.paymentInfo?.paymentMethod === 1 && <PodIcon />}
                                                {orderHistory?.paymentInfo?.paymentMethod === 2 && <CardIcon />}
                                                {orderHistory?.paymentInfo?.paymentMethod === 3 && <WalletIcon />}
                                                {orderHistory?.paymentInfo?.paymentMethod === 4 && 'Post Paid'}
                                                {orderHistory?.paymentInfo?.paymentMethod === 5 && <BankIcon />}
                                            </Text>
                                            <Text size="14px" color={colors.boldDark}>
                                                {paymentType(orderHistory?.paymentInfo?.paymentMethod) &&
                                                    `${paymentType(orderHistory?.paymentInfo?.paymentMethod)}`}
                                            </Text>
                                        </FlexWrapper>
                                        {orderHistory?.paymentInfo?.paymentMethod === 2 &&
                                            orderHistory?.customerDetails.phoneNumber && (
                                                <FlexWrapper>
                                                    <Text align="center" size="14px" color={colors.deepBlue}>
                                                        View transaction
                                                    </Text>
                                                </FlexWrapper>
                                            )}
                                    </FlexWrapper>
                                    <FlexWrapper>
                                        {orderHistory?.paymentInfo?.paymentMethod === 2 && (
                                            <>
                                                <MasterCardIcon />
                                                <Text fontWeight="400" color={colors.subGrey}>
                                                    ****6677
                                                </Text>
                                            </>
                                        )}
                                        {orderHistory?.paymentInfo?.paymentMethod === 3 && (
                                            <Text fontWeight="400" color={colors.subGrey}>
                                                Reference - {orderHistory?.paymentInfo?.transactionReference}
                                            </Text>
                                        )}
                                    </FlexWrapper>
                                </>
                            </Card>

                            {isB2BAgent && !!orderHistory?.totalUnpaidBalance && (
                                <>
                                    <OrderText weight="700" top="16px" left="16px" color={colors.subGrey}>
                                        REMITTANCE
                                    </OrderText>
                                    <Card margin="8px 16px">
                                        <FlexWrapper>
                                            <OrderText color={colors.lightDark}>Delivered value</OrderText>
                                            <OrderText weight="700">
                                                {formatPrice(orderHistory?.financialDetails?.deliveredValue)}
                                            </OrderText>
                                        </FlexWrapper>
                                        <FlexWrapper>
                                            <OrderText color={colors.lightDark}>Amount paid</OrderText>
                                            <OrderText weight="700">
                                                {formatPrice(orderHistory?.totalAmountPaid)}
                                            </OrderText>
                                        </FlexWrapper>
                                        <FlexWrapper>
                                            <OrderText color={colors.lightDark}>Balance</OrderText>
                                            <OrderText weight="700">
                                                {formatPrice(orderHistory?.totalUnpaidBalance)}
                                            </OrderText>
                                        </FlexWrapper>
                                        <FlexWrapper top="16px">
                                            <Button
                                                top="0px"
                                                border={`1px solid ${colors.lightGray2}`}
                                                backgroundColor={colors.white}
                                                color={colors.lightGray2}
                                                width="48%"
                                                onClick={() => {
                                                    history.push('/actions/remittance/remmitance-history');
                                                }}
                                            >
                                                Payment History
                                            </Button>
                                            <Button
                                                top="0px"
                                                width="48%"
                                                onClick={() => {
                                                    history.push('/actions/remittance/remitting-multiple-orders');
                                                }}
                                            >
                                                Remit payment
                                            </Button>
                                        </FlexWrapper>
                                    </Card>
                                </>
                            )}

                            <Card direction="row" margin="12px 0" justify="space-between">
                                <ItemText color={colors.subGrey}>Date</ItemText>
                                {orderHistory?.orderDate && <ItemText>{parseDate(orderHistory.orderDate)}</ItemText>}
                            </Card>

                            {complaintDetails && Object.keys(complaintDetails).length > 0 && (
                                <Container>
                                    <ComplaintDetails
                                        details={complaintDetails}
                                        comments={comments}
                                        files={complaintFiles}
                                    />
                                </Container>
                            )}

                            {complaintDetails && !Object.keys(complaintDetails)?.length && (
                                <Container>
                                    <RippleButton
                                        onClick={() => {
                                            mixPanel.track(LOG_COMPLAINT_START, {
                                                'Order Number': orderHistory?.orderNumber,
                                            });
                                            navigateToLogComplaint();
                                        }}
                                    >
                                        Dispute Order
                                    </RippleButton>
                                </Container>
                            )}
                        </div>
                    )}
                </ScreenContainer>
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default OrderDetails;
