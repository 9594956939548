import React, { lazy, Suspense } from 'react';

import { Loader } from '../components';
import { gameRoutes } from '../gamesApp/routes';
import {
    Home,
    Notifications,
    UpdatePassword,
    UpdateUserData,
    AccountSettings,
    DebitCardPage,
    AddDebitCard,
    BvnDetail,
    WalletPin,
    KYCPage,
    KYCMeansOfID,
    KYCRegionSelection,
    KYCAddressPage,
    FundWallet,
    AddBankAccount,
    ChoosePayment,
    ReferralPage,
    WithdrawalSettings,
    NextOfKin,
    TransactionDetails,
    WalletTransactions,
    ForgotTransactionPin,
    ForgotTransactionOtp,
    TransactionChangePin,
    PerformanceOrdersList,
    PerformanceOrderDetails,
    KYCAgentBankData,
    OTPVALIDATION403,
    SuccessPage,
    WalletToWalletList,
    WalletToWalletBeneficiaryList,
    WalletToWalletTransfer,
    WalletToWalletPin,
    WalletToBankList,
    WalletToBankBeneficiaryList,
    WalletToBankTransfer,
    WalletToBankPin,
    WalletAccount,
    AccountVerified,
    AgencyBankingSignup,
    IDCard,
    More,
    SellOnline,
    UpdateTransactionPin,
    PayBills,
    Commission,
    AirtimeRecharge,
    ShopSettings,
    NewSecurityQuestion,
} from '../views';

import { airtimeDataRoutes } from './actions/airtimeDataRoutes';
import { complaintsRoutes } from './actions/complaintsRoutes';
import { electricityPayRoutes } from './actions/electricityPayRoutes';
import { entertainmentPayRoutes } from './actions/entertainmentPayRoutes';
import { helpRoutesWithHeader } from './actions/helpRoutes';
import { merchBuyRoutesWithHeader } from './actions/merchbuyRoutes';
import { myAgentsRoutes } from './actions/myAgentsRoutes';
import { myMerchantsRoutes } from './actions/myMerchantsRoutes';
import { myShopRoutesWithHeader, myCustomerRoutesWithHeader, myNewShopRoutesWithHeader } from './actions/myShopRoutes';
import { paymentLinkProtectedRoutes } from './actions/paymentLinkRoutes';
import { remittanceRoutes } from './actions/remittanceRoute';
import { sendMoneyRoutes } from './actions/sendMoneyRoutes';
import { shsRoutes } from './actions/shsRoutes';
import { storeFrontRoutesWithHeader } from './actions/storeFrontRoutes';
import { transactionRoutesWithHeader } from './actions/transactionRoutes';
import { transportationPayRoutes } from './actions/transportationRoutes';
import { upgradeMerchantRoutes } from './actions/upgradeMerchantRoute';

// Trivia game routes

const PerformancePage = lazy(() => import('../views/home/performance/performance-page'));
const TransactionsPage = lazy(() => import('../views/home/transactions/transactions-page'));

export const protectedRoutes = [
    {
        path: '/my-shop',
        exact: true,
        main: () => <Home />,
        public: false,
    },
    {
        path: '/my-shop/storefront',
        exact: true,
        main: () => <Home activeIndex={1} />,
        public: false,
    },
    {
        path: '/my-shop/storefront/orders',
        exact: true,
        main: () => <Home activeIndex={1} selectedIndex={1} />,
        public: false,
    },
    {
        path: '/more',
        exact: true,
        main: () => <More />,
        public: false,
    },
    {
        path: '/sell',
        exact: true,
        main: () => <SellOnline />,
        public: false,
    },
    {
        path: '/user/notifications',
        exact: true,
        main: () => <Notifications />,
        public: false,
    },
    {
        path: '/user/update_transaction_pin',
        exact: true,
        main: () => <UpdateTransactionPin />,
        public: false,
    },
    {
        path: '/user/update_data',
        exact: true,
        main: () => <UpdateUserData />,
        public: false,
    },
    {
        path: '/user/account_next_of_kin',
        exact: true,
        main: () => <NextOfKin />,
        public: false,
    },
    {
        path: '/user/account_settings',
        exact: true,
        main: () => <AccountSettings />,
        public: false,
    },
    {
        path: '/user/account_kyc',
        exact: true,
        main: () => <KYCPage />,
        public: false,
    },
    {
        path: '/user/account_kyc_means',
        exact: true,
        main: () => <KYCMeansOfID />,
        public: false,
    },
    {
        path: '/user/account_kyc_region',
        exact: true,
        main: () => <KYCRegionSelection />,
        public: false,
    },
    {
        path: '/user/account_kyc_address',
        exact: true,
        main: () => <KYCAddressPage />,
        public: false,
    },
    {
        path: '/user/account_kyc_bank_data',
        exact: true,
        main: () => <KYCAgentBankData />,
        public: false,
    },
    {
        path: '/user/account_bank_data',
        exact: true,
        main: () => <BvnDetail />,
        public: false,
    },
    {
        path: '/user/wallet_pin',
        exact: true,
        main: () => <WalletPin />,
        public: false,
    },
    {
        path: '/user/password_update',
        exact: true,
        main: () => <UpdatePassword />,
        public: false,
    },
    {
        path: '/user/wallet_cards_all',
        exact: true,
        main: () => <DebitCardPage />,
        public: false,
    },
    {
        path: '/user/wallet_cards_add',
        exact: true,
        main: () => <AddDebitCard />,
        public: false,
    },
    {
        path: '/user/wallet_fund',
        exact: true,
        main: () => <FundWallet />,
        public: false,
    },
    {
        path: '/user/wallet_choose_payment',
        exact: true,
        main: () => <ChoosePayment />,
        public: false,
    },
    {
        path: '/user/wallet_withdrawal_settings',
        exact: true,
        main: () => <WithdrawalSettings />,
        public: false,
    },
    {
        path: '/user/wallet_add_bank_account',
        exact: true,
        main: () => <AddBankAccount />,
        public: false,
    },
    {
        path: '/user/wallet_transaction_success',
        exact: true,
        main: () => <SuccessPage />,
        public: false,
    },
    {
        path: '/user/wallet_to_wallet_list',
        exact: true,
        main: () => <WalletToWalletList />,
        public: false,
    },
    {
        path: '/user/wallet_to_wallet_list_more',
        exact: true,
        main: () => <WalletToWalletBeneficiaryList />,
        public: false,
    },
    {
        path: '/user/wallet_to_wallet_transfer',
        exact: true,
        main: () => <WalletToWalletTransfer />,
        public: false,
    },
    {
        path: '/user/wallet_to_wallet_transfer_pin',
        exact: true,
        main: () => <WalletToWalletPin />,
        public: false,
    },
    {
        path: '/user/wallet_to_bank_list',
        exact: true,
        main: () => <WalletToBankList />,
        public: false,
    },
    {
        path: '/user/wallet_to_bank_list_more',
        exact: true,
        main: () => <WalletToBankBeneficiaryList />,
        public: false,
    },
    {
        path: '/user/wallet_to_bank_transfer',
        exact: true,
        main: () => <WalletToBankTransfer />,
        public: false,
    },
    {
        path: '/user/wallet_to_bank_transfer_pin',
        exact: true,
        main: () => <WalletToBankPin />,
        public: false,
    },
    {
        path: '/user/wallet_account',
        exact: true,
        main: () => <WalletAccount />,
        public: false,
    },
    {
        path: '/user/account_verified',
        exact: true,
        main: () => <AccountVerified />,
        public: false,
    },
    {
        path: '/user/account_agency_banking_signup',
        exact: true,
        main: () => <AgencyBankingSignup />,
        public: false,
    },
    {
        path: '/user/account_referral',
        exact: true,
        main: () => <ReferralPage />,
        public: false,
    },
    {
        path: '/user/wallet_transactions',
        exact: true,
        main: () => (
            <Suspense fallback={<Loader />}>
                <TransactionsPage />
            </Suspense>
        ),
        public: false,
    },
    {
        path: '/user/wallet_transaction_details/:id?',
        exact: true,
        main: () => <TransactionDetails />,
        public: false,
    },
    {
        path: '/user/wallet_index',
        exact: true,
        main: () => <WalletTransactions />,
        public: false,
    },
    {
        path: '/user/performance',
        exact: true,
        main: () => (
            <Suspense fallback={<Loader />}>
                <PerformancePage />
            </Suspense>
        ),
        public: false,
    },
    {
        path: '/user/performance_orders',
        exact: true,
        main: () => <PerformanceOrdersList />,
        public: false,
    },
    {
        path: '/user/performance_order_details',
        exact: true,
        main: () => <PerformanceOrderDetails />,
        public: false,
    },
    {
        path: '/permission/new-device/',
        exact: true,
        main: () => <OTPVALIDATION403 />,
    },
    {
        path: '/user/id_card',
        exact: true,
        main: () => <IDCard />,
        public: false,
    },
    {
        path: '/user/wallet/pay-bills',
        exact: true,
        main: () => <PayBills />,
        public: false,
    },
    {
        path: '/user/wallet/airtime-recharge',
        exact: true,
        main: () => <AirtimeRecharge />,
        public: false,
    },
    {
        path: '/user/wallet_index/forgot-transaction-pin',
        exact: true,
        main: () => <ForgotTransactionPin />,
        public: false,
    },
    {
        path: '/user/wallet_index/forgot-transaction-otp',
        exact: true,
        main: () => <ForgotTransactionOtp />,
        public: false,
    },

    {
        path: '/user/wallet_index/transaction-change-pin',
        exact: true,
        main: () => <TransactionChangePin />,
        public: false,
    },
    {
        path: '/user/wallet_index/security-question',
        exact: true,
        main: () => <NewSecurityQuestion />,
        public: false,
    },
    {
        path: '/user/wallet/commission',
        exact: true,
        main: () => <Commission />,
        public: false,
    },
    {
        path: '/user/shop_settings',
        exact: true,
        main: () => <ShopSettings />,
        public: false,
    },

    ...myAgentsRoutes,
    ...myMerchantsRoutes,
    ...airtimeDataRoutes,
    ...electricityPayRoutes,
    ...transportationPayRoutes,
    ...entertainmentPayRoutes,
    ...myShopRoutesWithHeader,
    ...myNewShopRoutesWithHeader,
    ...myCustomerRoutesWithHeader,
    ...merchBuyRoutesWithHeader,
    ...sendMoneyRoutes,
    ...upgradeMerchantRoutes,
    ...transactionRoutesWithHeader,
    ...gameRoutes,
    ...helpRoutesWithHeader,
    ...shsRoutes,
    ...storeFrontRoutesWithHeader,
    ...paymentLinkProtectedRoutes,
    ...complaintsRoutes,
    ...remittanceRoutes,
];
