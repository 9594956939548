import React, { Fragment, useEffect, useState, Suspense } from 'react';

import algoliasearch from 'algoliasearch';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { DeliveryLocationPopup, TopHeader, InfiniteScrollList } from '../../../../components';
import { SearchInputWithCancel } from '../../../../components/forms/input/search-input/SearchInput';
import { merchbuyActions } from '../../../../redux/ducks/applications/merchbuy/actions';
import { CATEGORY_LIST } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { ListProductCategories } from '../components';
import ProductCategorySkeleton from '../components/listProductCategories/productCategorySkeleton';
import { Main, AddFlexBox, Container, Space } from '../styles';

const Flex = styled(AddFlexBox)`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: ${({ justifyContent }) => justifyContent || ''};
    width: 100%;
`;

const SearchContainer = styled.div`
    position: fixed;
    padding: 5px 0 0px;
    background-color: #fff;
    top: 125px;
    left: 0;
    right: 0;
    margin: auto;
    width: 343px;

    @media (max-width: 576px) {
        width: calc(100% - 32px);
        top: 62px;
    }
    z-index: 99;
`;

const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
);

const searchIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_V2;
const searchClient = algoliaClient.initIndex(searchIndex);

const MerchbuyProductCategories = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const productCategories = useSelector((state) => state.applications.merchbuy.merchbuyProductCategeries);
    const totalElements = useSelector((state) => state.applications.merchbuy.merchbuyProductCategoriesTotalElements);

    const [productsToDisplay, setProductsToDisplay] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage] = useState(100);
    const [hasMoreItems, setHasMoreItems] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [deliveryLocation, setDeliveryLocation] = useState(JSON.parse(localStorage.getItem('deliveryLocation')));
    const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(deliveryLocation === null);
    const [productsByCategories, setProductsByCategories] = useState([]);

    const getLocation = (data) => {
        if (data.state) {
            setDeliveryLocation(data);
            setOpenDeliverLocationPopup(!openDeliverLocationPopup);
        }
    };

    const fireAnalyticEvents = (timeDiff) => {
        mixPanel.track(CATEGORY_LIST, {
            'Time to first bite TTFB(ms)': timeDiff,
        });
    };

    useEffect(() => {
        const categories = productCategories.map((category) => `categoryId:${category.id}`);
        searchClient
            .search('', {
                filters: `state:${deliveryLocation.state} AND onMerchBuy:true AND quantityInStock > 0`,
                getRankingInfo: true,
                analytics: false,
                enableABTest: false,
                hitsPerPage: 1000,
                attributesToRetrieve: '*',
                attributesToSnippet: '*:20',
                snippetEllipsisText: '…',
                responseFields: '*',
                explain: '*',
                page: 0,
                maxValuesPerFacet: 10,
                facets: ['*'],
                facetFilters: [categories],
            })
            .then((res) => {
                if (res.hits?.length > 0) {
                    let groupByCategory = res.hits.reduce((group, product) => {
                        const { categoryName } = product;
                        group[categoryName] = group[categoryName] ?? [];
                        group[categoryName].push(product);
                        return group;
                    }, {});

                    groupByCategory = Object.values(groupByCategory);
                    setProductsByCategories(groupByCategory);
                }
            });
    }, [deliveryLocation.state, productCategories]);

    useEffect(() => {
        setHasMoreItems(productCategories?.length < totalElements);
    }, [productCategories, totalElements]);

    useEffect(() => {
        setProductsToDisplay(productCategories);
    }, [productCategories, page]);

    useEffect(() => {
        const start = new Date().getTime();
        dispatch(merchbuyActions.getProductCategories(page, perPage)).then(() => {
            fireAnalyticEvents(new Date().getTime() - start);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const addMoreDataToDisplay = async () => {
        if (productCategories?.length < totalElements) {
            const nextPage = productCategories.length / perPage + 1;
            const data = await dispatch(merchbuyActions.getProductCategories(nextPage, perPage));
            if (data) {
                setPage(nextPage);
            }
        } else {
            setHasMoreItems(false);
        }
    };

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader title={'Product Categories'} size={'14px'} weight={'400'} noBorderBottom />
                <Main>
                    <Container top={'60px'}>
                        <SearchContainer>
                            <SearchInputWithCancel
                                iconLeft={'-15px'}
                                margin="0px"
                                placeholder="Search for a product"
                                value={searchValue}
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                }}
                                onCancel={() => {
                                    setSearchValue('');
                                    history.goBack();
                                }}
                                showCancelOnInteract
                                onCancelInputValue={() => setSearchValue('')}
                            />
                        </SearchContainer>
                        <Suspense fallback={<ProductCategorySkeleton />}>
                            <InfiniteScrollList
                                data={productsToDisplay}
                                fetchMore={addMoreDataToDisplay}
                                hasMore={hasMoreItems}
                                endMessage=""
                            >
                                <Flex direction={'column'}>
                                    <ListProductCategories data={productsByCategories} />
                                </Flex>
                            </InfiniteScrollList>
                        </Suspense>
                    </Container>
                </Main>
                <Space height="50px" />
                {openDeliverLocationPopup && (
                    <DeliveryLocationPopup
                        open={openDeliverLocationPopup}
                        getLocation={getLocation}
                        cancel={() => {
                            setOpenDeliverLocationPopup(!openDeliverLocationPopup);
                        }}
                    />
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default MerchbuyProductCategories;
