import React, { Fragment, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { DeliveryLocationPopup, SuccessPage } from '../../../../../components';
import { ScreenContainer } from '../../../../../containers/ScreenContainer';
import { orderStatus } from '../../../../../enums';
import History from '../../../../../utils/History';
import { SUCCESSFUL_CHECKOUT } from '../../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../../utils/mix-panel/mixPanel';
import { getProductInfoForMixPanel } from '../helper';

const SuccessfulOrder = ({ orderNumber, paymentMethod, priceInfo, statusEnum, setStep }) => {
    const cartItems = useSelector((state) => state.applications.merchbuy.shopCart);
    const walletStatus = useSelector((state) => state.account.wallet.status);
    const { subTotal } = priceInfo;
    const [orderProcessed, setOrderProcessed] = useState(false);
    const [paymentProcessed, setPaymentProcessed] = useState(false);
    const [deliveryLocation, setDeliveryLocation] = useState(JSON.parse(localStorage.getItem('deliveryLocation')));
    const [openDeliverLocationPopup, setOpenDeliverLocationPopup] = useState(deliveryLocation === null);

    useEffect(() => {
        const data = getProductInfoForMixPanel(cartItems);
        mixPanel.track(SUCCESSFUL_CHECKOUT, {
            'Total cart value': subTotal,
            'Total item count': data.totalItemCount,
            'Total unique item': cartItems.length,
            'Total shops': data.sortedItems.length,
            'Item ids': data.productIds,
            'Item names': data.productNames,
            'Item shopNames': data.shopNames,
            'Item categories': data.categoryNames,
            'Wallet Status': walletStatus,
            'Payment type': paymentMethod.name,
            Time: new Date().toLocaleString(),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (statusEnum === 0) {
            setOrderProcessed(true);
            setPaymentProcessed(true);
        } else if (statusEnum === 1) {
            setOrderProcessed(false);
            setPaymentProcessed(false);
        } else {
            setOrderProcessed(true);
            setPaymentProcessed(false);
        }
    }, [statusEnum]);

    const getLocation = (data) => {
        if (data.state) {
            setDeliveryLocation(data);
            setOpenDeliverLocationPopup(!openDeliverLocationPopup);
        }
    };

    return (
        <Fragment>
            <ScreenContainer>
                <SuccessPage
                    title={
                        orderProcessed && !paymentProcessed
                            ? 'Transaction failed!'
                            : orderProcessed
                            ? 'Order Successful'
                            : 'Order failed!'
                    }
                    subtitle={
                        orderProcessed && !paymentProcessed
                            ? 'We could not process your payment at this time'
                            : orderProcessed
                            ? `Your Order No. ${orderNumber} was placed successfully. You can view your recent order in your order history page.`
                            : 'We can’t process your order now. Look through your cart to make sure the items there are still available'
                    }
                    showDetailButton={orderProcessed}
                    successfull={orderProcessed && paymentProcessed}
                    doneText={
                        orderProcessed && !paymentProcessed
                            ? 'Try Again'
                            : orderProcessed
                            ? 'Continue Shopping'
                            : 'Ok, Go to Cart'
                    }
                    detailText={paymentProcessed ? 'View Order History' : 'Back To Summary'}
                    onDoneClick={() => {
                        if (orderProcessed && paymentProcessed) {
                            History.push('/actions/merchbuy');
                        } else if (!orderProcessed) {
                            History.goBack();
                        } else {
                            setStep(orderStatus.delivery);
                        }
                    }}
                    onDetailClick={async () => {
                        if (paymentProcessed) {
                            History.push('/actions/merchbuy/order-history');
                        } else {
                            setStep(orderStatus.delivery);
                        }
                    }}
                />
            </ScreenContainer>
            {openDeliverLocationPopup && (
                <DeliveryLocationPopup
                    open={openDeliverLocationPopup}
                    getLocation={getLocation}
                    cancel={() => {
                        setOpenDeliverLocationPopup(!openDeliverLocationPopup);
                    }}
                />
            )}
        </Fragment>
    );
};

export default SuccessfulOrder;
