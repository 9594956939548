import React, { Fragment } from 'react';

import styled from 'styled-components';

import { ReactComponent as SmsIcon } from '../../../../../../assets/sms-latest.svg';
import { ReactComponent as CallIcon } from '../../../../../../assets/voice-call.svg';
import { ReactComponent as WhatsappIcon } from '../../../../../../assets/whatsapp-new.svg';
import { TopHeader, PageLogo } from '../../../../../../components';
import { Message, PageTitle } from '../../../../../../containers/MessageContainer';
import { ScreenContainer, FlexCenteredBlock } from '../../../../../../containers/ScreenContainer';
import { colors } from '../../../../../../styles';
import Avatar from '../../../assets/avatar.svg';

const Container = styled.div`
    background-color: ${({ bgColor }) => bgColor};
    height: 76px;
    border-radius: 8px;
    margin: ${({ margin }) => margin};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &:hover {
        cursor: pointer;
    }
`;

const Text = styled.p`
    font-size: 14px;
    color: ${colors.boldDark};
    font-weight: 500;
    line-height: 21px;
    margin-left: 13px;
`;

export const ChooseOtpChannel = ({ cancel, setOtpChannel }) => {
    return (
        <Fragment>
            <TopHeader
                backAction={() => cancel()}
                title={'OTP Verification'}
                withSpacesHeader
                backLink={'#'}
                noBorderBottom
            />
            <ScreenContainer top={'64px'}>
                <FlexCenteredBlock>
                    <PageLogo
                        Icon={Avatar}
                        width={'72px'}
                        height={'72px'}
                        iconHeight={'54px'}
                        iconWidth={'53px'}
                        background={colors.lightPink}
                    />
                    <PageTitle
                        size={'24px'}
                        weight={700}
                        lineHeight={'36px'}
                        top={'16px'}
                        color={colors.themeTextColor10}
                        bottom={'4px'}
                    >
                        Verifying Merchant
                    </PageTitle>
                    <Message
                        top="0px"
                        align={'center'}
                        bottom={'19px'}
                        maxWidth={'296px'}
                        color={colors.themeTextColor11}
                    >
                        Let’s verify the merchant’s phone number. Select an option the merchant can use to receive an
                        OTP
                    </Message>
                    <Container
                        margin={'0 0 16px'}
                        bgColor={colors.aliceBlue}
                        onClick={() => {
                            setOtpChannel('sms');
                        }}
                    >
                        <SmsIcon />
                        <Text>Send Via Text Message</Text>
                    </Container>
                    <Container
                        margin={'0 0 16px'}
                        bgColor={colors.orange2}
                        onClick={() => {
                            setOtpChannel('voice');
                            cancel();
                        }}
                    >
                        <CallIcon />
                        <Text>Receive a Voice Call</Text>
                    </Container>
                    <Container
                        bgColor={colors.lightGreen2}
                        onClick={() => {
                            setOtpChannel('whatsapp');
                        }}
                    >
                        <WhatsappIcon />
                        <Text>Send via Whatsapp</Text>
                    </Container>
                </FlexCenteredBlock>
            </ScreenContainer>
        </Fragment>
    );
};
