import React, { useState } from 'react';

import { string, func } from 'prop-types';
import Cropper from 'react-easy-crop';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Overlay } from '../../../containers/OverlayContainer';
import { colors } from '../../../styles';
import { RippleButton } from '../../button';
import { PopUp, PopUpContent } from '../common';

export const convertToBlob = (blob) => {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
        var base64data = reader.result;
        return base64data;
    };
};

export const toDataURL = (url) =>
    fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                }),
        );

const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = await createImage(imageSrc);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    image.width = image.width / 2;
    image.height = image.height / 2;

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    if (!ctx) {
        return null;
    }

    const getRadianAngle = (degreeValue) => {
        return (degreeValue * Math.PI) / 180;
    };

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width, safeArea / 2 - image.height);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(data, 0 - safeArea / 2 + image.width - pixelCrop.x, 0 - safeArea / 2 + image.height - pixelCrop.y);

    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            resolve(URL.createObjectURL(file));
        }, 'image/jpeg');
    });
};

const Container = styled.div`
  position: absolute;
  width: 100%;
  display: ${({ showCropper }) => (showCropper ? 'block' : 'none')}
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: inherit;
  margin: auto;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
  cursor: pointer;
`;

const CropperFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    position: absolute;
    background-color: white;
    z-index: 10000;
    border-bottom: none;
    left: 0px;
    right: 0px;
    bottom: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 375px;
`;

const ButtonDiv = styled.div`
    display: flex;
    width: 80%;
    justify-content: space-between;
`;

const StyledButton = styled(RippleButton)`
    border: ${({ border }) => border || null};
    background-color: ${({ bgColor }) => bgColor || null};
    color: ${({ color }) => color || 'white'};
    width: ${({ width }) => width || '35%'};
    margin-top: 0;
`;

export const ImageCropper = ({
    avatar,
    onCancel,
    onSave,
    buttonWidth,
    cancelBgColor,
    cancelBorder,
    cancelColor,
    cancelText,
    saveText,
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [aspect] = useState(4 / 3);
    const [croppedImage, setCroppedImage] = useState(avatar);

    const onCropComplete = async (_, croppedAreaPixels) => {
        const cropped = await getCroppedImg(avatar, croppedAreaPixels);
        setCroppedImage(cropped);
    };

    return (
        <PopUp open={true}>
            <Overlay bgc={'rgba(0,0,0,0.5)'} />
            <PopUpContent height={'700px'}>
                <Container>
                    <Cropper
                        image={avatar}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        onMediaLoaded={(size) => {
                            const { width, height } = size;
                            if (width <= 50 || height <= 50) {
                                toast.error('Image is too small.');
                            }
                        }}
                    />

                    <CropperFooter>
                        <ButtonDiv>
                            <StyledButton
                                border={cancelBorder}
                                width={buttonWidth}
                                onClick={onCancel}
                                bgColor={cancelBgColor || colors.red}
                                color={cancelColor}
                            >
                                {cancelText || 'Cancel'}
                            </StyledButton>
                            <StyledButton width={buttonWidth} onClick={() => onSave(croppedImage)}>
                                {saveText || 'Save'}
                            </StyledButton>
                        </ButtonDiv>
                    </CropperFooter>
                </Container>
            </PopUpContent>
        </PopUp>
    );
};

ImageCropper.propTypes = {
    onCancel: func,
    onSave: func,
    url: string,
};
