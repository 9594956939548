import React, { Fragment } from 'react';

import styled from 'styled-components';

import { ReactComponent as SmsIcon } from '../../../../assets/sms-latest.svg';
import { ReactComponent as CallIcon } from '../../../../assets/voice-call.svg';
import { ReactComponent as WhatsappIcon } from '../../../../assets/whatsapp-new.svg';
import { TopHeader } from '../../../../components';
import { Message, PageTitle } from '../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { colors } from '../../../../styles';

const Container = styled.div`
    background-color: ${({ bgColor }) => bgColor};
    height: 76px;
    border-radius: 8px;
    margin: ${({ margin }) => margin};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &:hover {
        cursor: pointer;
    }
`;

const Text = styled.p`
    font-size: 14px;
    color: ${colors.boldDark};
    font-weight: 500;
    line-height: 21px;
    margin-left: 13px;
`;

export const ForgotTransactionPinChooseOtpChannel = ({ cancel, setOtpChannel }) => {
    return (
        <Fragment>
            <TopHeader title={'Wallet pin reset'} withSpacesHeader noBorderBottom backLink={'/user/wallet_index'} />
            <ScreenContainer top={'64px'}>
                <PageTitle
                    size={'16px'}
                    weight={700}
                    align={'left'}
                    lineHeight={'24px'}
                    color={colors.boldDark}
                    bottom={'4px'}
                >
                    OTP Verification
                </PageTitle>
                <Message
                    top="0px"
                    align={'left'}
                    weight={400}
                    bottom={'34px'}
                    maxWidth={'296px'}
                    color={colors.themeTextColor11}
                >
                    To make sure it is you, we will send an OTP to your phone number. Kindly select an option for us to
                    send the OTP
                </Message>
                <Container
                    margin={'0 0 16px'}
                    bgColor={colors.aliceBlue}
                    onClick={() => {
                        setOtpChannel('SMS');
                        cancel();
                    }}
                >
                    <SmsIcon />
                    <Text>Send Via Text Message</Text>
                </Container>
                <Container
                    margin={'0 0 16px'}
                    bgColor={colors.orange2}
                    onClick={() => {
                        setOtpChannel('VOICE');
                        cancel();
                    }}
                >
                    <CallIcon />
                    <Text>Receive a Voice Call</Text>
                </Container>
                <Container
                    bgColor={colors.lightGreen2}
                    onClick={() => {
                        setOtpChannel('WHATSAPP');
                        cancel();
                    }}
                >
                    <WhatsappIcon />
                    <Text>Send via Whatsapp</Text>
                </Container>
            </ScreenContainer>
        </Fragment>
    );
};
