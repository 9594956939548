import React, { Fragment, useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as ShareIcon } from '../../../../assets/share.svg';
import { TopHeader, OptionsPopupDialog, Loader, ComplaintDetails } from '../../../../components';
import { List, ListSubHeading, ListHighlight } from '../../../../containers/ListContainer';
import { TransactionStatus, StatusText } from '../../../../containers/MessageContainer';
import { ScreenContainer, Container } from '../../../../containers/ScreenContainer';
import { getTransactionDetail } from '../../../../redux/ducks/account/transactions/actions';
import { getComplaintDetails, getComplaintComments } from '../../../../redux/ducks/applications/complaints/actions';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { formatCreationDate } from '../../../../utils/date/formatCreationDate';
import History from '../../../../utils/History';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import TransactionCard from '../transaction-card';

import { ReactComponent as FailedIcon } from './assets/failed.svg';
import { ReactComponent as PendingIcon } from './assets/pending.svg';
import { ReactComponent as ReportIcon } from './assets/report.svg';
import { ReactComponent as SuccessIcon } from './assets/success.svg';

export const TransactionDetail = styled(ListHighlight)`
    color: ${({ color }) => color || colors.boldDark};
    font-size: 14px;
    font-weight: 400;
    max-width: 140px;
    position: inherit;
    margin: 0px;
`;

const DescriptionText = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: #071827;
    margin: ${({ margin }) => margin};
    overflow-wrap: break-word;
`;

const HeaderSpace = styled.div`
    margin: 16px 0 0 0;
    border-bottom: 1px solid #ececf2;
`;

export const TransactionCategory = styled(ListSubHeading)`
    margin: 0;
    color: #718596;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
`;

export const TransactionListItem = styled.div`
    padding: 0px 16px;
    margin-top: ${({ top }) => top || '24px'};
    display: ${({ display }) => display || 'flex'};
    justify-content: space-between;
    align-items: center;
`;

export const CompliantText = styled.p`
    position: absolute;
    right: 0;
    top: 12px;
    margin-right: 16px;
    color: #227eff;
    font-size: 14px;
    font-weight: 400;
    align-items: center;
`;

export const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
`;

const TransactionDetails = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [openOption, setOpenOptions] = useState(false);
    const [transaction, setTransaction] = useState(location.state);
    const [complaintDetails, setComplaintDetails] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [complaintFiles, setComplaintFiles] = useState([]);
    const [comments, setComments] = useState([]);
    const { pathname } = location;
    const pattern = `(.*)?${'/user/wallet_transaction_details/:id'}`;
    const match = matchPath(pathname, { path: pattern }) || {};
    const departmentId = process.env.REACT_APP_UX_SUPPORT_DEPARTMENT_ID; //country specific
    const issueTitle = 'wallet';
    const category = 'wallet';

    const transactions = useSelector((state) => state.account.transactions.transactionsList);

    const fetchData = useCallback(async () => {
        const response = await dispatch(getTransactionDetail(match.params?.id));
        response && setTransaction(response);
        setIsLoading(false);
    }, [dispatch, match.params]);

    useEffect(() => {
        if (match?.params?.id && !transaction) {
            const currentTransaction = transactions.find(
                (transaction) => transaction.transactionReference === match.params?.id,
            );

            if (currentTransaction) {
                setTransaction(currentTransaction);
                setIsLoading(false);
            } else {
                fetchData();
            }
        }
    }, [dispatch, fetchData, match, transaction, transactions]);

    useEffect(() => {
        if (transaction?.transactionReference) {
            dispatch(getComplaintDetails(transaction?.transactionReference)).then((data) => {
                if (data) {
                    setComplaintDetails(data);
                } else setComplaintDetails({});
            });
            setIsLoading(false);
        }
    }, [dispatch, transaction, location]);

    useEffect(() => {
        if (complaintDetails?.id) {
            dispatch(getComplaintComments(complaintDetails?.id)).then((data) => {
                const { ticketFiles, ticketComments } = data || {};
                if (ticketFiles?.length > 0 || ticketComments?.length > 0) {
                    setComplaintFiles(ticketFiles);
                    setComments([...ticketFiles, ...ticketComments]);
                } else {
                    return setComments([]);
                }
            });
        }
    }, [dispatch, complaintDetails]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                <TopHeader noBorderBottom title={'Details'} backLink="/user/wallet_index">
                    {complaintDetails && !Object.keys(complaintDetails).length && (
                        <CompliantText
                            onClick={() => {
                                History.push({
                                    pathname: '/actions/complaints/log_complaint',
                                    state: {
                                        reference: transaction?.transactionReference,
                                        departmentId,
                                        issueTitle,
                                        category,
                                    },
                                });
                            }}
                        >
                            Log complaint
                        </CompliantText>
                    )}
                </TopHeader>
                {transaction ? (
                    <ScreenContainer top="45px">
                        <TransactionCard noBorderBottom transaction={transaction} />
                        <HeaderSpace />
                        <List fullScreen noBorderBottom>
                            <TransactionListItem>
                                <TransactionCategory>Status</TransactionCategory>
                                <StatusWrapper>
                                    <Fragment>
                                        {transaction.transactionStatus === 'PENDING' && <PendingIcon />}
                                        {transaction.transactionStatus === 'FAILED' && <FailedIcon />}
                                        {transaction.transactionStatus === 'SUCCESSFUL' && <SuccessIcon />}
                                        {transaction.transactionStatus === 'REFUNDED' && <SuccessIcon />}
                                        <StatusText type={transaction.transactionStatus}>
                                            {transaction.transactionStatus.toLowerCase()}
                                        </StatusText>
                                    </Fragment>
                                </StatusWrapper>
                                <ListHighlight>
                                    <TransactionStatus
                                        type={transaction.commissionDetails && transaction.commissionDetails.status}
                                    >
                                        {transaction.commissionDetails && transaction.commissionDetails.status}
                                    </TransactionStatus>
                                </ListHighlight>
                            </TransactionListItem>
                            <TransactionListItem>
                                <TransactionCategory>Initiated on</TransactionCategory>
                                <TransactionDetail>{formatCreationDate(transaction.createdDate)}</TransactionDetail>
                            </TransactionListItem>
                            <TransactionListItem>
                                <TransactionCategory>Confirmed on</TransactionCategory>
                                <TransactionDetail>
                                    {transaction.confirmedDate && formatCreationDate(transaction.confirmedDate)}
                                </TransactionDetail>
                            </TransactionListItem>
                            <TransactionListItem>
                                <TransactionCategory>Transaction class</TransactionCategory>
                                <TransactionDetail>{transaction.transactionClass}</TransactionDetail>
                            </TransactionListItem>
                            <TransactionListItem>
                                <TransactionCategory>Transaction type</TransactionCategory>
                                <TransactionDetail>{transaction.transactionType}</TransactionDetail>
                            </TransactionListItem>
                            <TransactionListItem>
                                <TransactionCategory>Transaction Reference</TransactionCategory>
                                <TransactionDetail>{transaction.transactionReference}</TransactionDetail>
                            </TransactionListItem>
                            <TransactionListItem>
                                <TransactionCategory>Sender’s Name</TransactionCategory>
                                <TransactionDetail>{transaction.senderName}</TransactionDetail>
                            </TransactionListItem>
                            <TransactionListItem>
                                <TransactionCategory>Receiver’s Name</TransactionCategory>
                                <TransactionDetail>{transaction.receiverName}</TransactionDetail>
                            </TransactionListItem>
                            <TransactionListItem>
                                <TransactionCategory>Charge</TransactionCategory>
                                <TransactionDetail>{formatPrice(transaction.transactionCharge)}</TransactionDetail>
                            </TransactionListItem>
                            <TransactionListItem>
                                <TransactionCategory>Starting Balance</TransactionCategory>
                                <TransactionDetail>{formatPrice(transaction.startingBalance)}</TransactionDetail>
                            </TransactionListItem>
                            <TransactionListItem>
                                <TransactionCategory>Final Balance</TransactionCategory>
                                <TransactionDetail>{formatPrice(transaction.finalBalance)}</TransactionDetail>
                            </TransactionListItem>
                            <TransactionListItem display="grid">
                                <TransactionCategory>Description</TransactionCategory>
                                <DescriptionText>{transaction.description}</DescriptionText>
                            </TransactionListItem>

                            {complaintDetails && Object.keys(complaintDetails).length > 0 && (
                                <Container>
                                    <ComplaintDetails
                                        details={complaintDetails}
                                        comments={comments}
                                        files={complaintFiles}
                                    />
                                </Container>
                            )}
                        </List>
                        <OptionsPopupDialog
                            open={openOption}
                            cancel={() => setOpenOptions(!openOption)}
                            title={'Options'}
                            items={[
                                {
                                    Icon: ShareIcon,
                                    title: 'Share',
                                    click: () => {},
                                },
                                {
                                    Icon: ReportIcon,
                                    title: 'Report',
                                    click: () => {},
                                },
                            ]}
                        />
                    </ScreenContainer>
                ) : (
                    <ScreenContainer></ScreenContainer>
                )}
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default TransactionDetails;
