import React, { useEffect, useState, Fragment } from 'react';

import styled from 'styled-components';

import { SearchInputWithCancel } from '../../../../../components/forms/input/search-input/SearchInput';
import { List, ListCheckedRow, ListItem } from '../../../../../containers/ListContainer';
import avatarBlue from '../assets/avatarBlue.png';
import avatarGreen from '../assets/avatarGreen.png';
import avatarOrange from '../assets/avatarOrange.png';
import avatarRed from '../assets/avatarRed.png';

const SelectCustomerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
`;

const Container = styled.div`
    width: 100%;
`;

const ContactsWrapper = styled.div`
    overflow-y: auto;
    height: 45vh;
`;

const Text = styled.div`
    color: ${({ color }) => color || '#212c3d'};
    font-size: ${({ size }) => size || '14px'};
    margin-top: ${({ top }) => top || ''};
    margin-bottom: ${({ bottom }) => bottom || ''};
    margin-left: ${({ left }) => left || ''};
    margin-right: ${({ right }) => right || ''};
    font-weight: ${({ weight }) => weight || ''};
    text-align: ${({ align }) => align || null};
`;

export const SelectCustomer = ({ setOpen, customers, selectedCustomer, setSelectedCustomer }) => {
    const [allCustomers, setAllCustomers] = useState(customers);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        setAllCustomers(
            customers.filter(
                (customer) =>
                    customer.name.includes(searchValue) ||
                    customer.name.toLowerCase().includes(searchValue) ||
                    customer.phoneNumber.includes(searchValue),
            ),
        );
    }, [searchValue, customers]);

    const avatarArray = [
        avatarBlue,
        avatarRed,
        avatarGreen,
        avatarOrange,
        avatarBlue,
        avatarRed,
        avatarGreen,
        avatarOrange,
    ];

    return (
        <>
            <Container>
                <SearchInputWithCancel
                    placeholder={'Search for Customer'}
                    onChange={(e) => setSearchValue(e.target.value)}
                />

                <SelectCustomerContainer>
                    <ContactsWrapper>
                        <List noBorderBottom>
                            {allCustomers.map((customer, index) => {
                                return (
                                    <Fragment key={index}>
                                        <ListItem
                                            padding="0"
                                            noBorderBottom
                                            onClick={() => {
                                                if (selectedCustomer && customer.id === selectedCustomer.id) {
                                                    setSelectedCustomer(undefined);
                                                } else {
                                                    setSelectedCustomer({
                                                        customerName: customer.name,
                                                        customerPhoneNumber: customer.phoneNumber,
                                                    });
                                                }
                                            }}
                                        >
                                            <img
                                                alt="avatar"
                                                width="32"
                                                height="33"
                                                src={avatarArray[Math.floor(Math.random() * avatarArray.length)]}
                                            />
                                            <ListCheckedRow
                                                onClick={setOpen}
                                                className={
                                                    (selectedCustomer && selectedCustomer.customerPhoneNumber) ===
                                                    customer.phoneNumber
                                                        ? 'active'
                                                        : ''
                                                }
                                            >
                                                <div>
                                                    <Text align="left" color="#071827">
                                                        {customer.name}
                                                    </Text>
                                                    <Text align="left" color="#718596" top="6px" size="12px">
                                                        {customer.phoneNumber}
                                                    </Text>
                                                </div>
                                            </ListCheckedRow>
                                        </ListItem>
                                    </Fragment>
                                );
                            })}
                        </List>
                    </ContactsWrapper>
                </SelectCustomerContainer>
            </Container>
        </>
    );
};
