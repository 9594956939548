import React, { Fragment, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as SmsIcon } from '../../../../assets/sms-latest.svg';
import { ReactComponent as CallIcon } from '../../../../assets/voice-call.svg';
import { ReactComponent as WhatsappIcon } from '../../../../assets/whatsapp-new.svg';
import { TopHeader } from '../../../../components';
import { Message, PageTitle } from '../../../../containers/MessageContainer';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import {
    sendDeviceVerificationOtp,
    sendDeviceVerificationOtpViaChannelType,
    resendDeviceVerificationOtp,
} from '../../../../redux/ducks/auth/check/actions';
import { colors } from '../../../../styles';
import { insertZero } from '../../../../utils/inputs/formatPhoneNumber';

const Container = styled.div`
    background-color: ${({ bgColor }) => bgColor};
    height: 76px;
    border-radius: 8px;
    margin: ${({ margin }) => margin};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &:hover {
        cursor: pointer;
    }
`;

const Text = styled.p`
    font-size: 14px;
    color: ${colors.boldDark};
    font-weight: 500;
    line-height: 21px;
    margin-left: 13px;
`;

export const ChooseOtpChannel = ({ cancel, setShowOtpPage, resend }) => {
    const dispatch = useDispatch();
    const msisdn = useSelector((state) => state.user.msisdn);

    const [otpChannel, setOtpChannel] = useState('');

    useEffect(() => {
        const onSubmit = async () => {
            let res;
            if (resend) {
                res = await dispatch(resendDeviceVerificationOtp(otpChannel, msisdn));
            } else {
                if (otpChannel === 'SMS') {
                    res = await dispatch(sendDeviceVerificationOtp(insertZero(msisdn)));
                } else if (otpChannel === 'VOICE') {
                    res = await dispatch(sendDeviceVerificationOtpViaChannelType('voice', insertZero(msisdn)));
                } else if (otpChannel === 'WHATSAPP') {
                    res = await dispatch(sendDeviceVerificationOtpViaChannelType('whatsapp', insertZero(msisdn)));
                }
            }
            res && cancel();
            res && setShowOtpPage(true);
        };
        otpChannel && onSubmit();
        setOtpChannel('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, otpChannel]);

    return (
        <Fragment>
            <TopHeader title={'OTP Verification'} withSpacesHeader noBorderBottom backLink={'#'} backAction={cancel} />
            <ScreenContainer top={'64px'}>
                <PageTitle
                    size={'16px'}
                    top={'40px'}
                    weight={700}
                    align={'center'}
                    lineHeight={'24px'}
                    color={colors.boldDark}
                    bottom={'4px'}
                >
                    Where do you want to get your OTP?
                </PageTitle>
                <Message top="0px" align={'center'} weight={400} bottom={'34px'} color={colors.themeTextColor11}>
                    Kindly select an option for us to send the OTP
                </Message>
                <Container margin={'0 0 16px'} bgColor={colors.lightGreen2} onClick={() => setOtpChannel('WHATSAPP')}>
                    <WhatsappIcon />
                    <Text>Send via Whatsapp</Text>
                </Container>
                <Container margin={'0 0 16px'} bgColor={colors.orange2} onClick={() => setOtpChannel('VOICE')}>
                    <CallIcon />
                    <Text>Receive a Voice Call</Text>
                </Container>
                <Container bgColor={colors.aliceBlue} onClick={() => setOtpChannel('SMS')}>
                    <SmsIcon />
                    <Text>Send Via Text Message</Text>
                </Container>
            </ScreenContainer>
        </Fragment>
    );
};
