import React, { Fragment, useEffect, useState } from 'react';

import algoliasearch from 'algoliasearch';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import aa from 'search-insights';
import styled from 'styled-components';

import {
    Button,
    MerchbuyAddtoCartPopup,
    PageLogo,
    RippleLink,
    RippleButton,
    TopHeader,
    InfiniteScrollList,
} from '../../../../components';
import { SearchInputWithCancel } from '../../../../components/forms/input/search-input/SearchInput';
import { SavedItemsLoader } from '../../../../components/loader';
import { ReactComponent as CloseIcon } from '../../../../components/popup/merchbuy-add-to-cart-popup/assets/close.svg';
import { BlurBackground } from '../../../../containers/BlurBackground';
import { ScreenContainer } from '../../../../containers/ScreenContainer';
import { merchbuyActions } from '../../../../redux/ducks/applications/merchbuy/actions';
import { colors } from '../../../../styles';
import { formatPrice } from '../../../../utils/currency/formatPriceWithComma';
import { INITIATE_ADD_TO_CART, VIEW_SAVED_ITEMS } from '../../../../utils/mix-panel/constants';
import { mixPanel } from '../../../../utils/mix-panel/mixPanel';
import DesktopBackgroundLayout from '../../../DesktopBackgroundLayout';
import { ReactComponent as EmptyStateIcon } from '../assets/empty-saved-items.svg';
import ProductFallbackImage from '../assets/productFallbackImage.png';
import { Cart } from '../components';
import { ReactComponent as DeleteIcon } from '../shopping-cart/assets/deleteIcon.svg';
import { Main } from '../styles';

import { OOSEducation, OOSEducationBackground } from './educate';

const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
);

const searchIndex = process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_V2;
const searchClient = algoliaClient.initIndex(searchIndex);

const SavedItemsContainer = styled.div`
    margin: 8px 16px 4px 16px;
    background: ${colors.switch.noob};
    position: relative;
    height: 136px;
    border: none;
    border-radius: 4px;
    padding: 3px 15px 15px 15px;
    box-shadow: 0px 15px 84px rgb(0 0 0 / 5%);

    &:nth-of-type(1),
    &:nth-of-type(2) {
        z-index: ${({ zIndex }) => zIndex || null};
    }
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${({ top }) => top || '16px'};
`;

const StyledCloseIcon = styled(CloseIcon)`
    margin-right: 25px;
`;

const StyledEmptyStateIcon = styled(EmptyStateIcon)`
    margin: ${({ margin }) => margin || '50% 0 0 20%'};
`;

const StyledBoxIcon = styled.input`
    margin-top: 17px;
    margin-left: 16px;
    width: 24px;
    border-radius: 4px;
    height: 24px;
    border: 1px solid #cbd6e0;
`;

const Items = styled.div`
    margin-left: 15px;
    color: ${colors.themeTxtColor10};
    font-size: 12px;
    font-weight: 400;
`;

const EmptyStateText = styled.h2`
    margin-top: 20px;
    color: ;
    color: ${({ color }) => color || `${colors.themeTxtColor10}`};
    font-size: ${({ size }) => size || '16px'};
    font-weight: ${({ weight }) => weight || '700'};
    text-align: center;
    line-height: 21px;
`;

const Price = styled.span`
    margin-top: 4px;
    color: ${colors.themeTxtColor10};
    font-size: 12px;
    font-weight: 700;
`;

const Border = styled.div`
    border: 0.5px solid ${colors.borderColor};
    margin-top: 16px;
`;

const RemoveCartWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65px;
    margin-top: 17px;
`;

const RemoveText = styled.div`
    color: ${colors.themeTextColor11};
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.01em;
    cursor: pointer;
`;
const FilterTitle = styled.div`
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    padding: 0px 15% 0px 10px;
    color: ${colors.themeTxtColor10};
`;

const ButtonWrapper = styled.div`
    margin-left: ${({ left }) => left || '65%'};
    margin-top: ${({ top }) => top || '-8%'};
    width: ${({ width }) => width || '100%'};
`;
const Backdrop = styled.div`
    height: 100%;
    width: 100%;
`;

const ModalContainer = styled.div`
    height: ${({ height }) => height || 'calc(236px + 66px)'};
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px 8px 0 0;
    @media (max-width: 576px) {
        height: ${({ minHeight }) => minHeight || 'auto'};
    }
    padding: 24px 0px 0px;
`;

const OutOfStockTotal = styled.div`
    color: ${colors.themeTextColor11};
    font-weight: 400;
    font-size: 14px;
    margin-left: ${({ left }) => left || '52%'};
    margin-top: 20px;
`;
const OutOfStockText = styled.h2`
    color: ${colors.themeTxtColor10};
    font-weight: 400;
    font-size: 14px;
    margin-left: 16px;
    margin-top: 20px;
`;

const SavedItems = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const allSavedListItems = useSelector((state) => state.applications.merchbuy.savedList);
    const allPages = useSelector((state) => state.applications.merchbuy.savedListPages);
    const loading = useSelector((state) => state.applications.merchbuy.isLoading);
    const userId = useSelector((state) => state.user.userId);

    const [filterType, setFilterType] = useState([]);
    const [inStockChecked, setInStockChecked] = useState(false);
    const [allItemsChecked, setAllItemsChecked] = useState(false);
    const [outOfStockChecked, setOutOfStockChecked] = useState(false);
    const [newSavedItemsList, setNewSavedItemsList] = useState([]);
    const [specificItemsDetails, setSpecificItemDetails] = useState({});
    const [openCartPopup, setOpenCartPopup] = useState(false);
    const [onFilterClick, setOnFilterClick] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [doAnimate, setDoAnimate] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);

    const pageSize = 20;
    const oosEducation = localStorage.getItem('OOSEducation') && JSON.parse(localStorage.getItem('OOSEducation'));
    const [userEducate, setUserEducate] = useState(oosEducation?.savedItems ? false : true);

    const inStock = newSavedItemsList?.filter(
        (item) =>
            (item?.quantityInStock || item?.quantity) >=
            (item?.bulkPrices[0]?.moq || item?.bulkPrices[0]?.quantity || item?.moq),
    );
    const outOfStock = newSavedItemsList?.filter(
        (item) =>
            (item?.quantityInStock || item?.quantity) <
                (item?.bulkPrices[0]?.moq || item?.bulkPrices[0]?.quantity || item?.moq) ||
            (item?.quantityInStock || item?.quantity) === 0,
    );

    const setSavedEducation = () =>
        localStorage.setItem('OOSEducation', JSON.stringify({ ...oosEducation, savedItems: true }));

    const addMoreDataToDisplay = async () => {
        if (page < allPages) {
            const data = await dispatch(merchbuyActions.getSavedItems(page, pageSize));
            setPage(page + 1);
            setNewSavedItemsList([...newSavedItemsList, ...data]);
        } else {
            setHasMore(false);
        }
    };

    useEffect(() => {
        dispatch(merchbuyActions.getSavedItems(page, pageSize));
    }, [dispatch, page]);

    const stockFilter = () => {
        if (allItemsChecked) {
            setNewSavedItemsList(newSavedItemsList);
        } else {
            const stockProducts = newSavedItemsList?.map((item) => {
                if (
                    (item?.quantityInStock || item?.quantity) >=
                    (item?.bulkPrices[0].moq || item?.bulkPrices[0]?.quantity || item?.moq)
                )
                    return { ...item, stock: 'inStock' };
                return { ...item, stock: 'outOfStock' };
            });
            const sortedList = filterType?.map((type) => stockProducts?.filter((item) => item.stock === type));
            const concatArray = sortedList[0].concat(sortedList[1]);
            if (sortedList.length === 1 && !sortedList[0].length) {
                toast.success('There are no out of stock products on your saved items list');
                return;
            }
            setNewSavedItemsList(sortedList.length > 1 ? concatArray : sortedList[0]);
        }
    };

    const handleDelete = async (id) => dispatch(merchbuyActions.deleteSavedItems(id));

    const getProductFromAlgolia = async (productId, warehouseId) => {
        const result = await searchClient.search('', {
            facetFilters: [`productId: ${productId}`, `warehouseId: ${warehouseId}`],
        });

        return result.hits[0];
    };

    const handleAddToCart = async (product) => {
        mixPanel.track(INITIATE_ADD_TO_CART, {
            'name of the product': product?.productName || product?.name,
            'product id': product?.productId || product?.id,
        });

        aa('convertedObjectIDs', {
            userToken: userId,
            eventName: 'Product Added to Cart',
            index: searchIndex,
            objectIDs: [product?.productId || product?.id],
        });

        setSpecificItemDetails(product);
        setOpenCartPopup(!openCartPopup);
    };

    useEffect(() => {
        const getAllSavedItemsFromAlgolia = async () => {
            const savedItemsArray = await Promise.all(
                allSavedListItems?.map((product) => getProductFromAlgolia(product.id, product.warehouseId)),
            );
            setNewSavedItemsList(savedItemsArray);
        };

        getAllSavedItemsFromAlgolia();
    }, [allSavedListItems]);

    const handleClose = () => {
        setDoAnimate(true);
        setTimeout(() => {
            setOpenCartPopup(!openCartPopup);
            setDoAnimate(false);
        }, 300);
    };

    useEffect(() => {
        mixPanel.track(VIEW_SAVED_ITEMS, {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        allSavedListItems &&
            setNewSavedItemsList(
                allSavedListItems.filter((data) =>
                    data?.productName?.toLowerCase().includes(searchValue?.toLowerCase()),
                ),
            );
    }, [searchValue, allSavedListItems]);

    return (
        <Fragment>
            <DesktopBackgroundLayout>
                {loading && <SavedItemsLoader />}
                {onFilterClick && (
                    <BlurBackground position="fixed" zIndex="2000">
                        <Backdrop>
                            <ModalContainer height="calc(300px + 80px)" minHeight="calc(300px + 80px)">
                                <FlexContainer>
                                    <FilterTitle>Filter</FilterTitle>
                                    <StyledCloseIcon onClick={() => setOnFilterClick(false)} />
                                </FlexContainer>
                                <Border />
                                <FlexContainer top="0">
                                    <StyledBoxIcon
                                        type="checkbox"
                                        checked={outOfStockChecked}
                                        onChange={(e) => {
                                            setOutOfStockChecked(e.nativeEvent.target.checked);

                                            let list = [...filterType];
                                            if (outOfStockChecked) {
                                                let newFilter = list.filter((x) => x !== 'outOfStock');
                                                list = [...newFilter];
                                                setFilterType([...list]);
                                            } else {
                                                list.push('outOfStock');
                                                setFilterType([...list]);
                                            }
                                        }}
                                    />
                                    <OutOfStockText>Out of Stock</OutOfStockText>
                                    <OutOfStockTotal>{outOfStock.length || 0}</OutOfStockTotal>
                                </FlexContainer>
                                <Border />
                                <FlexContainer top="0">
                                    <StyledBoxIcon
                                        type="checkbox"
                                        checked={inStockChecked}
                                        onChange={(e) => {
                                            setInStockChecked(e.nativeEvent.target.checked);

                                            let list = [...filterType];
                                            if (inStockChecked) {
                                                let newFilter = list.filter((x) => x !== 'inStock');
                                                list = [...newFilter];
                                                setFilterType([...list]);
                                            } else {
                                                list.push('inStock');
                                                setFilterType([...list]);
                                            }
                                        }}
                                    />
                                    <OutOfStockText>In Stock</OutOfStockText>
                                    <OutOfStockTotal left="60%">{inStock.length || 0}</OutOfStockTotal>
                                </FlexContainer>
                                <Border />
                                <FlexContainer top="0">
                                    <StyledBoxIcon
                                        type="checkbox"
                                        checked={allItemsChecked}
                                        onChange={(e) => {
                                            setAllItemsChecked(e.nativeEvent.target.checked);
                                        }}
                                    />
                                    <OutOfStockText>All Items</OutOfStockText>
                                    <OutOfStockTotal left="60%">{newSavedItemsList?.length || 0}</OutOfStockTotal>
                                </FlexContainer>
                                <ButtonWrapper
                                    left="16px"
                                    top="24px"
                                    onClick={() => {
                                        stockFilter();
                                        setOnFilterClick(false);
                                    }}
                                >
                                    <Button
                                        color={colors.popup.cancelButton}
                                        width="93%"
                                        height="45px"
                                        backgroundColor={colors.themeTextColor12}
                                        top={'1px'}
                                        onClick={() => ''}
                                    >
                                        Apply
                                    </Button>
                                </ButtonWrapper>
                            </ModalContainer>
                        </Backdrop>
                    </BlurBackground>
                )}
                <Fragment>
                    {newSavedItemsList?.length === 0 && !loading ? (
                        <Fragment>
                            <Fragment>
                                <TopHeader title="My Saved Items">
                                    <Cart color={colors.cartColor} circleRight="20px" right="20px" />
                                </TopHeader>
                                {allSavedListItems && allSavedListItems.length > 0 && (
                                    <ScreenContainer top="56px" paddingBottom="0">
                                        <SearchInputWithCancel
                                            placeholder={'Search for a product'}
                                            value={searchValue}
                                            onChange={(e) => {
                                                setSearchValue(e.target.value);
                                            }}
                                            onClick={() => setOnFilterClick(true)}
                                        />
                                    </ScreenContainer>
                                )}
                                <StyledEmptyStateIcon margin={'20% 0 0 20%'} />
                                <EmptyStateText>
                                    {allSavedListItems?.length > 0 && searchValue.length > 0
                                        ? 'No Items Found'
                                        : 'You are yet to add items'}
                                </EmptyStateText>
                                <EmptyStateText color={colors.themeSubColor1} size="14px" weight="400">
                                    Items you like and set <br /> notifications for appear here
                                </EmptyStateText>
                                <ButtonWrapper left="0" top="0">
                                    <RippleButton
                                        width="90%"
                                        height="48px"
                                        backgroundColor={colors.deepBlue}
                                        top={'20%'}
                                        left="20px"
                                        onClick={() => {
                                            history.push('/actions/merchbuy');
                                        }}
                                    >
                                        Go to Merchbuy
                                    </RippleButton>
                                </ButtonWrapper>
                            </Fragment>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {!loading && (
                                <Fragment>
                                    <TopHeader title="My Saved Items">
                                        <Cart color={colors.cartColor} circleRight="20px" right="20px" />
                                    </TopHeader>
                                    {userEducate && <OOSEducationBackground />}
                                    <ScreenContainer top="56px" paddingBottom="0">
                                        <SearchInputWithCancel
                                            placeholder={'Search for a product'}
                                            value={searchValue}
                                            onChange={(e) => {
                                                setSearchValue(e.target.value);
                                            }}
                                            onClick={() => setOnFilterClick(true)}
                                        />
                                    </ScreenContainer>
                                    <InfiniteScrollList
                                        data={newSavedItemsList || []}
                                        fetchMore={addMoreDataToDisplay}
                                        hasMore={hasMore}
                                        endMessage="Well! That's all"
                                    >
                                        {userEducate && (
                                            <OOSEducation
                                                top={newSavedItemsList?.length > 1 ? '450px' : '300px'}
                                                title={'My Saved Items:'}
                                                text={'View the list of all liked items'}
                                                setUserEducate={setUserEducate}
                                                setSavedEducation={setSavedEducation}
                                                content={'flex-end'}
                                                justifyContent={'flex-end'}
                                                arrowOne
                                            />
                                        )}
                                        <Main bg="#F9FAFC" paddingTop="10px" paddingBottom="10px" position="relative">
                                            {!loading &&
                                                newSavedItemsList
                                                    ?.sort(function (a, b) {
                                                        let x = a?.productName?.toLowerCase();
                                                        let y = b?.productName?.toLowerCase();
                                                        if (x < y) {
                                                            return -1;
                                                        }
                                                        if (y < x) {
                                                            return 1;
                                                        }
                                                        return null;
                                                    })
                                                    .map((item, index) => (
                                                        <SavedItemsContainer
                                                            key={index}
                                                            zIndex={userEducate ? '2000' : ''}
                                                        >
                                                            <FlexContainer>
                                                                <RippleLink
                                                                    to={{
                                                                        pathname: `/actions/merchbuy/product/${
                                                                            item?.id || item?.productId
                                                                        }`,
                                                                        state: item,
                                                                    }}
                                                                >
                                                                    <PageLogo
                                                                        width={'44px'}
                                                                        height={'44px'}
                                                                        iconWidth={'44px'}
                                                                        iconHeight={'44px'}
                                                                        Icon={
                                                                            item?.images?.baseImageUrl ||
                                                                            item?.image ||
                                                                            ProductFallbackImage
                                                                        }
                                                                        fallback={ProductFallbackImage}
                                                                        borderRadius="2px"
                                                                    />
                                                                </RippleLink>
                                                                <Items>
                                                                    {item &&
                                                                        `${item?.brandName} - ${item?.productName} - ${item?.productVariantName}`.toLowerCase()}
                                                                    <br />

                                                                    <Price>
                                                                        {formatPrice(
                                                                            item?.bulkPrices?.length
                                                                                ? Math?.min(
                                                                                      ...item?.bulkPrices?.map(
                                                                                          (item) => item?.price,
                                                                                      ),
                                                                                  )
                                                                                : Math?.min(item?.maxPrice),
                                                                        )}
                                                                    </Price>
                                                                </Items>
                                                            </FlexContainer>
                                                            <Border />
                                                            <RemoveCartWrapper
                                                                onClick={() => {
                                                                    handleDelete(item?.id || item?.productId);
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                                <RemoveText>REMOVE</RemoveText>
                                                            </RemoveCartWrapper>
                                                            {item?.quantityInStock >=
                                                                (item?.bulkPrices[0]?.moq || item?.moq) &&
                                                            item?.onMerchBuy ? (
                                                                <ButtonWrapper>
                                                                    <RippleButton
                                                                        width="35%"
                                                                        height="26px"
                                                                        backgroundColor={colors.deepBlue}
                                                                        top={'1px'}
                                                                        onClick={() => {
                                                                            handleAddToCart(item);
                                                                        }}
                                                                    >
                                                                        Add to cart
                                                                    </RippleButton>
                                                                </ButtonWrapper>
                                                            ) : !item?.onMerchBuy ? (
                                                                <ButtonWrapper left={'50%'}>
                                                                    <Button
                                                                        color={colors.darkRed}
                                                                        width="50%"
                                                                        height="20px"
                                                                        backgroundColor={colors.newRedBg}
                                                                        top={'5px'}
                                                                        size={'12px'}
                                                                    >
                                                                        Product Unavailable
                                                                    </Button>
                                                                </ButtonWrapper>
                                                            ) : (
                                                                <ButtonWrapper left={'70%'}>
                                                                    <Button
                                                                        color={colors.darkRed}
                                                                        width="30%"
                                                                        height="20px"
                                                                        backgroundColor={colors.newRedBg}
                                                                        top={'1px'}
                                                                    >
                                                                        Out of Stock
                                                                    </Button>
                                                                </ButtonWrapper>
                                                            )}
                                                        </SavedItemsContainer>
                                                    ))}
                                        </Main>
                                    </InfiniteScrollList>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Fragment>
                <MerchbuyAddtoCartPopup
                    open={openCartPopup}
                    data={specificItemsDetails}
                    cancel={handleClose}
                    confirm={merchbuyActions.addProductToCart}
                    animate={doAnimate}
                />
            </DesktopBackgroundLayout>
        </Fragment>
    );
};

export default SavedItems;
