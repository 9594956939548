import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { countriesData, flags } from '../../../../data/countries';
import { colors } from '../../../../styles';
import { PhoneNumberPopup } from '../../../popup/phone-number-popup';
import { SearchInput } from '../search-input/SearchInput';

const Wrapper = styled.div`
    margin-bottom: 35px;

    .margin {
        margin: 0 24px;
    }
`;
const CountryWrapper = styled.div`
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CountryFlag = styled.img`
    width: 36px;
    height: 18px;
`;

const FlagWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Country = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
    margin-left: 14px;
`;

const Code = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.boldDark};
`;

const CountryOutterWrapper = styled.div`
    max-height: 150px;
    overflow-y: scroll;
`;

const Dropdown = ({ open, cancel, setCountry, setCode }) => {
    const [text, setText] = useState('');
    const [countries, setCountries] = useState(countriesData);

    const searchArr = (searchTerm) => {
        if (searchTerm === '') {
            setCountries(countriesData);
        }

        setCountries(
            countriesData.filter((entry) => entry?.name && entry.name.toLowerCase().includes(searchTerm.toLowerCase())),
        );
    };

    useEffect(() => {
        searchArr(text);
    }, [text]);
    return (
        <PhoneNumberPopup height="296px" open={open} cancel={cancel}>
            <Wrapper>
                <div className="margin">
                    <SearchInput
                        fromModal={true}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Search"
                    />
                </div>
                <CountryOutterWrapper>
                    <div className="margin">
                        {countries.map((data, index) => (
                            <CountryWrapper
                                onClick={() => {
                                    setCode(data.code);
                                    setCountry(flags.find((flag) => flag.label === data.name)?.customAbbreviation);
                                    cancel();
                                }}
                                key={index}
                            >
                                <FlagWrapper>
                                    <CountryFlag src={data.flag} alt={data.name} />
                                    <Country>{data.name}</Country>
                                </FlagWrapper>
                                <Code>{data.code}</Code>
                            </CountryWrapper>
                        ))}
                    </div>
                </CountryOutterWrapper>
            </Wrapper>
        </PhoneNumberPopup>
    );
};

export default Dropdown;
